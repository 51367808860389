import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import './CreateWkPopUp.css';

export default function CreateWkPopUp({ toggle, setToggle, goToIntegrations }) {
  const intl = useIntl();
  const [wkName, selectWorkflow] = useState('enrichment');
  return (
    <Modal show={toggle} onHide={setToggle} className="fade create-workflow" id="create-wk-pop-up">
      <Modal.Header closeButton className="modal-header">
        <div className="modal-inner-header">
          <p className="title">
            <FormattedMessage id={'workflow.newWorkflow'} />
          </p>
        </div>
      </Modal.Header>
      <ModalBodyWrapper wkName={wkName} intl={intl}>
        <WkTemplate
          name={'enrichment'}
          image={'enrichment-image.png'}
          checked={wkName === 'enrichment'}
          onChange={selectWorkflow}
        />
        <WkTemplate
          name={'integration'}
          image={'integration-image.png'}
          checked={wkName === 'integration'}
          onChange={selectWorkflow}
        />
      </ModalBodyWrapper>
      <div className="modal-footer">
        {wkName === 'integration' ? (
          <a href={'#'} className="btn1 w-141 text-center" onClick={() => goToIntegrations(true)}>
            <FormattedMessage id={'workflow.proceed'} />
          </a>
        ) : (
          <NavLink className="btn1 w-141 text-center" to={'/createworkflow/' + wkName}>
            <FormattedMessage id={'workflow.proceed'} />
          </NavLink>
        )}
      </div>
    </Modal>
  );
}

function WkTemplate({ name, image, checked, onChange }) {
  return (
    <div className={`template-grid`}>
      <input
        type="radio"
        name="template"
        checked={checked}
        onChange={(e) => onChange(name, e)}
        id={name}
      />

      <div className="grid-inner-ui">
        <div className="icon-ui" />
        <div className="img-block">
          <img src={'/assets/images/' + image} alt="" />
        </div>
        <div className="content-block">
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {' '}
            <FormattedMessage id={'workflow.' + name} />{' '}
            {/*{name == "linkedin" && <span className={"beta-badge"}>BETA</span>}*/}
          </p>
        </div>
      </div>
    </div>
  );
}

function ModalBodyWrapper({ children, wkName, intl }) {
  const WORKFLOW_INFOS = {
    linkedin: intl.formatMessage({ id: 'workflow.linkedinWkText' }),
    enrichment: intl.formatMessage({ id: 'workflow.enrichmentText' }),
    integration: intl.formatMessage({ id: 'workflow.integrationText' }),
  };
  return (
    <Modal.Body className="modal-body">
      <div className="create-workflow-wrap">
        <label className="sub-title2">
          <FormattedMessage id={'workflow.chooseATemplate'} />
        </label>
        <div className="template-listing">{children}</div>
        <div className="info-content">
          <p>{WORKFLOW_INFOS[wkName.trim()]}</p>
        </div>
      </div>
    </Modal.Body>
  );
}
