import InitialAvatar from '../../InitialsAvatar/InitialsAvatar';
import React from 'react';
const maxImagesToShow = 4;

export const getMembersContent = (members, totalImages) => {
  let content = [];
  const maxImages = totalImages >= maxImagesToShow ? maxImagesToShow : totalImages;
  for (let i = 0; i < maxImages; i++) {
    if (members[i].picture) {
      content.push(
        <img
          className="membersAvatar"
          key={`${members[i].firstName} ${members[i].lastName} ${i}`}
          src={`${members[i].picture}.jpg`}
          alt={`${members[i].firstName} ${members[i].lastName} ${i}`}
        />,
      );
    } else {
      content.push(
        <InitialAvatar
          key={`${members[i].firstName} ${members[i].lastName} ${i}`}
          className="membersAvatar"
          firstName={members[i].firstName}
          lastName={members[i].lastName}
        />,
      );
    }
  }
  return content;
};
export const getOverlayText = (totalImages) => {
  if (totalImages > maxImagesToShow) {
    const extraImages = totalImages - maxImagesToShow;
    return <div className="membersOverlay">+{extraImages > 99 ? 99 : extraImages}</div>;
  } else {
    return null;
  }
};
