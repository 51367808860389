import React, { useContext, useEffect, useRef, useState } from 'react';
import { integrationUpdatedNames } from '../../../Settings/Integration/constants';
import { capitalizeFirstLetter } from '../../../../services/utils/tools';
import { getAccounts } from '../../../../services/api/integrations.api';
import { actions, WorkflowContext } from '../../store/workflow.store';
import './IntegrationsDropdown.css';
import { FormattedMessage } from 'react-intl';

function IntegrationsDropdown({
  integrations,
  setIsMappingModalOpen,
  setIsConnectionModalOpen,
  defaultName,
  isOpen,
  setIsOpen,
  handleSettingsToggle,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionError, setSelectedOptionError] = useState(false);
  const [selectedOptionConnected, setSelectedOptionConnected] = useState(false);
  const { state, dispatch } = useContext(WorkflowContext);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectOption = (integration) => {
    setSelectedOption(integration.name);
    dispatch({
      type: actions.SET_SELECTED_INTEGRATION,
      payload: integration.name,
    });
    if (integration.name !== defaultName && !integration.isConnected) {
      setSelectedOptionError(true);
      setSelectedOptionConnected(false);
    } else if (
      integration.name === defaultName ||
      (integration.name !== defaultName && integration.isConnected)
    ) {
      setSelectedOptionError(false);
      setSelectedOptionConnected(true);
    }

    // DISPATCH TO DO
    setIsOpen(false);
  };

  const handleClick = (header) => {
    selectOption(header);
  };

  const selectedValue = selectedOption ? selectedOption : defaultName;

  const getBlock = (id) => {
    return state.selectedTemplate.blocks.find((block) => block.id == id);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    if (selectedOption) {
      handleSettingsToggle(getBlock(2));
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedOption]);

  return (
    <>
      <div id="integrations-dropdown" className={selectedOptionConnected ? 'flex-row' : ''}>
        <div className={`dropdown-container`} ref={dropdownRef}>
          <div
            className={`${isOpen ? 'button is-open' : 'button'} ${
              selectedOptionError ? 'button-error' : ''
            }`}
            onClick={toggleDropdown}
          >
            <div className="inner-input">
              {integrations.map((integration, index) =>
                integration.name === selectedValue && integration.imagesrc ? (
                  <img src={integration.imagesrc} alt={integration.name} />
                ) : null,
              )}
              <div>
                <p>
                  {selectedValue !== defaultName
                    ? capitalizeFirstLetter(integrationUpdatedNames[selectedValue])
                    : selectedValue}
                </p>
                <span className="radio-icon"></span>
              </div>
            </div>
            <img height={'auto'} src={'/assets/images/ic-arrow-bottom.png'} alt={'arrow down'} />
          </div>
          {/* DROPDOWN OPEN */}
          {isOpen && (
            <ul>
              <div className="ul-title">
                <FormattedMessage id="settings.integration.selectAnIntegration" />
              </div>
              {integrations.map((integration, index) => (
                <li className="intg-li">
                  <div className="li-left">
                    <div className="img-block">
                      <img src={integration.imagesrc} alt="" />
                    </div>
                    <div className="">
                      <p>{capitalizeFirstLetter(integrationUpdatedNames[integration.name])}</p>
                      {integration.isConnected ? (
                        <div className="connected">
                          <div className="dot active-color"></div>
                          <div className="active">
                            <FormattedMessage id={'workflow.active'} />
                          </div>
                        </div>
                      ) : (
                        <div className="connected">
                          <div className="dot not-active-color"></div>
                          <div className="not-active">
                            <FormattedMessage id={'workflow.notActive'} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="selectintegration"
                    onClick={() => selectOption(integration)}
                    checked={selectedValue === integration.name}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
        {selectedOptionConnected && (
          <div className="configure-mapping">
            <button className="btn9" onClick={() => setIsMappingModalOpen(true)}>
              <FormattedMessage id="workflow.configureMapping" />
            </button>
          </div>
        )}
        {selectedOptionError && (
          <div className="error">
            <img src="/assets/images/exclamation-mark.svg" alt="exclamation mark" />
            <p>
              <FormattedMessage id="workflow.integrationNotConnected" />
            </p>
            <button className="btn9" onClick={() => setIsConnectionModalOpen(true)}>
              <FormattedMessage id="workflow.connectNow" />
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default IntegrationsDropdown;
