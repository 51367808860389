import React, { Component, useEffect, useState } from 'react';
import qs from 'querystring';
import './style.css';
import { integrationAuthConnect } from '../../../services/api/integrations.api';
import { messages_en } from '../../../services/translations';
import { CLIENT_ID_ZOHO } from '../../../constants';
import { func } from 'prop-types';
import { useLocation } from 'react-router-dom';

export default function AuthComplete(props) {
  const [state, setState] = useState({
    loading: false,
    error: false,
    login: '',
    isCrmVerified: '',
  });
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const code = qs.parse(location.search)['?code'];
      setState({ ...state, loading: true });
      if (code && code !== 'undefined') {
        try {
          let email = '';
          let result = null;
          if (location.pathname.indexOf('hubspot') > -1) {
            result = await integrationAuthConnect({
              code,
              integrationName: 'hubspot',
            });
            email = result && result.data && result.data['hubspotEmail'];
            if (result && result.statusCode >= 400)
              setState({
                ...state,
                error: result.data.messageTranslated || result.data.message,
              });
          } else if (location.pathname.indexOf('zoho') > -1) {
            const domain = qs.parse(location.search)['location'].toUpperCase();
            result = await integrationAuthConnect({
              code,
              integrationName: 'zohocrm',
              domain,
            });
            email = result && result.data && result.data['zohocrmEmail'];
            if (result && result.statusCode >= 400)
              setState({
                ...state,
                error: result.data.messageTranslated || result.data.message,
              });
          } else if (location.pathname.indexOf('salesforce') > -1) {
            result = await integrationAuthConnect({
              code,
              integrationName: 'salesforce',
            });
            if (result && result.statusCode >= 400)
              setState({
                ...state,
                error: result.data.messageTranslated || result.data.message,
              });
            email = result && result.data && result.data['salesforceEmail'];
          } else {
            result = await integrationAuthConnect({
              code,
              integrationName: 'gmail',
            });
            if (result && result.statusCode >= 400)
              setState({
                ...state,
                error: result.data.messageTranslated || result.data.message,
              });
            email = result && result.data && result.data['gmailEmail'];
          }
          setState({
            ...state,
            login: email,
            isCrmVerified: result && result.data && result.data.isCrmVerified,
          });
        } catch (err) {
          setState({ ...state, loading: false, error: err, login: 'err' });
        } finally {
          localStorage.setItem('login', state.login);
          localStorage.setItem('isCrmVerified', state.isCrmVerified);
          localStorage.setItem('loginError', state.error);
          setState({ ...state, loading: false });
          window.open('', '_parent', '');
          window.close();
        }
      }
    })();
  }, []);
  return (
    <div className="AuthComplete">
      {state.loading && (
        <img src={'/assets/images/loading.gif'} alt={'loading'} style={{ height: '110px' }} />
      )}
      {state.error && <div>Error</div>}
    </div>
  );
}
