import React, { useContext, useState } from 'react';
import * as Permissions from '../../../../components/Permissions/Permissions';
import { Animation, PaginationInput, SelectPicker } from '../../../../components';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { exportCreditsActivity } from '../../../../services/api/credits.api';
import download from 'js-file-download';
import { OrganizationContext } from '../../store/Organization.store';
import useFullPageLoader from '../../../../components/FullPageLoader/FullPageLoader';
import MemberSelect from '../../common/MemberSelect/MemberSelect';
import Calendar from 'react-calendar';

export default function ActivityFilters({
  page,
  handleGoToPage,
  pages,
  membersLeavedOrg,
  handleActivityFilter,
  checkPlanActivityPermissions,
}) {
  const { state, dispatch } = useContext(OrganizationContext);
  const [loader, handleLoader, setCounter] = useFullPageLoader();
  const [startDateToggle, setStartDateToggle] = useState(false);
  const [endDateToggle, setEndDateToggle] = useState(false);
  const [excelModal, setExcelModal] = useState(false);
  const [exportType, setExportType] = useState(false);

  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(25000);
  const EXCEL_LIMIT = 25000;
  let chunks = [{ start: 1, end: EXCEL_LIMIT }];
  while (chunks[chunks.length - 1].end < state.activityTotalUsages)
    chunks.push({
      start: chunks[chunks.length - 1].end + 1,
      end: Math.min(chunks[chunks.length - 1].end + EXCEL_LIMIT, state.activityTotalUsages),
    });
  const handleFilterBySource = async (sources) => {
    handleActivityFilter({
      ...state.activityFilters,
      sources,
    });
  };

  const handleFilterByMember = async (member) => {
    handleActivityFilter({
      ...state.activityFilters,
      member: member.email || undefined,
    });
  };

  const handleFilterByDate = async (date, type) => {
    if (type === 'start') {
      handleActivityFilter({ ...state.activityFilters, startDate: date });
      setStartDateToggle(false);
    } else {
      handleActivityFilter({
        ...state.activityFilters,
        endDate: date,
      });
      setEndDateToggle(false);
    }
  };

  const onCreditsUsagesExport = async (type) => {
    let data, statusCode;
    handleLoader(true);
    setCounter(null);
    setExportType(type);
    ({ data, statusCode } = await exportCreditsActivity({
      ...state.activityFilters,
      startDate: state.activityFilters.startDate
        ? state.activityFilters.startDate
        : state.organization.createdAt,
      emails: (state.activityFilters.member && [state.activityFilters.member]) || undefined,
      creditTypes: ['exportCredits'],
      exportType: type == 'Export CSV' ? 'CSV' : 'EXCEL',
      skip: start - 1,
      limit: end - start + 1,
    }));
    if (statusCode === 200) {
      download(data, 'credits_activities.csv');
    }
    if (state.activityTotalUsages > EXCEL_LIMIT && !excelModal) {
      setExcelModal(true);
      handleLoader(false);
    }
    if (statusCode === 200)
      download(data, 'credits_activities.' + (type == 'Export CSV' ? 'csv' : 'xlsx'));
    handleLoader(false);
  };

  return (
    <>
      {loader}
      <div className="head_block" style={{ zIndex: 11, padding: '16' }}>
        <div className="left_block">
          <div
            style={{
              color: ' #414766',
              fontSize: '17px',
              fontWeight: 500,
              letterSpacing: '0.16px',
              lineHeight: '16px',
            }}
          >
            <p>
              <FormattedMessage id={'organization.activity.creditUsageHistory'} />
            </p>
          </div>
        </div>
        <div className="export-dropdown-ui ">
          <div className={'export-btn'}>
            {!checkPlanActivityPermissions('Export') ? (
              <Permissions.CallToAction
                withoutIcon={true}
                toolTipPosition={'left'}
                isPlan={true}
                toolTipStyle={{ width: 200 }}
              >
                <SelectPicker
                  values={['Export CSV', 'Export Excel']}
                  onChange={onCreditsUsagesExport}
                  labelsStyle={{ left: 0, width: '100%' }}
                  selectedLabelStyle={{
                    width: '105px',
                    justifyContent: 'right',
                  }}
                  staticLabel={'Export'}
                />
              </Permissions.CallToAction>
            ) : (
              <SelectPicker
                values={['Export CSV', 'Export Excel']}
                onChange={onCreditsUsagesExport}
                labelsStyle={{ left: 0, width: '100%' }}
                selectedLabelStyle={{
                  width: '105px',
                  justifyContent: 'right',
                }}
                staticLabel={'Export'}
              />
            )}
          </div>
        </div>
        {excelModal && (
          <Modal
            show={true}
            onHide={() => setExcelModal(false)}
            className="fade add-member-wrapper  addmember-modal"
          >
            <Modal.Header className="modal-header border-0" closeButton>
              <div className="modal-inner-header">
                <div className="profile-info">
                  <h5 className="heading mb-8">
                    <FormattedMessage id={'organization.excelExport'} />
                  </h5>
                </div>
              </div>
            </Modal.Header>
            <div className="modal-body p-0">
              <div className="add-member-fields">
                <form>
                  <div className="modal-heading">
                    <span>
                      <FormattedMessage
                        id={'organization.touWantToGenerateAnExcel'}
                        values={{
                          value: state.activityTotalUsages,
                        }}
                      />
                    </span>
                    <br />
                    <br />
                    <span>
                      <FormattedMessage
                        id={'organization.weAreSorryToInformYou'}
                        values={{
                          value: EXCEL_LIMIT,
                        }}
                      />
                    </span>
                    <span>
                      <FormattedMessage
                        id={'organization.howeverYouCanExport'}
                        values={{
                          value: EXCEL_LIMIT,
                        }}
                      />
                    </span>
                  </div>

                  <div className="form-wrap">
                    <div className="form-wrap">
                      {chunks.map((chunk, index) => {
                        return (
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <input type="Number" value={chunk.start} disabled={true} />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <input type="Number" value={chunk.end} disabled={true} />
                              </div>
                            </div>
                            <a
                              href="#"
                              className="col-md-4"
                              style={{
                                paddingTop: '12px',
                                fontSize: '14px',
                              }}
                              onClick={async () => {
                                handleLoader(true);
                                setCounter(null);
                                let { data, statusCode } = await exportCreditsActivity({
                                  startDate: state.activityFilters.startDate
                                    ? state.activityFilters.startDate
                                    : state.organization.createdAt,
                                  endDate: state.activityFilters.endDate,
                                  member:
                                    state.activityFilters.member &&
                                    state.activityFilters.member.email,
                                  types: state.activityFilters.types,
                                  skip: chunk.start,
                                  limit: chunk.end - chunk.start + 1,
                                  exportType: exportType == 'Export CSV' ? 'CSV' : 'EXCEL',
                                });
                                if (statusCode === 200) download(data, 'credits_activities.xlsx');
                                handleLoader(false);
                              }}
                            >
                              <FormattedMessage id={'organization.downloadFile'} />
                              {index + 1}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer border-0">
              <a href="#" className="btn4 mr14 btn48" onClick={() => setExcelModal(false)}>
                <FormattedMessage id={'cancel'} />
              </a>
            </div>
          </Modal>
        )}
      </div>
      <div className={'head_block'} style={{ justifyContent: 'left' }}>
        <div className={'sorting-block'} style={{ display: 'flex', alignItems: 'center' }}>
          <label>
            <FormattedMessage id={'organization.filterBy'} />
          </label>

          <div>
            <MemberSelect
              members={[...state.organization.members, ...membersLeavedOrg]}
              onChange={handleFilterByMember}
              labelsStyle={{ width: '130%' }}
            />
          </div>
          <SelectSource
            title={'Sources'}
            list={[
              'publicApi',
              'plugin',
              'dashboard',
              'workflow',
              'creditsWithdrawal',
              'renew',
              'upgrade',
              'freeCredits',
              'recurrentCredits',
            ]}
            onChange={handleFilterBySource}
          />
          <DatePicker
            date={new Date(state.activityFilters.startDate)}
            dateToggle={startDateToggle}
            setDateToggle={setStartDateToggle}
            selectDate={(date) => handleFilterByDate(date, 'start')}
            title={<FormattedMessage id={'organization.start'} />}
            minDate={state.chartFilters.minDate}
            monthly={state.chartFilters.monthly}
          />
          <DatePicker
            date={new Date(state.activityFilters.endDate)}
            dateToggle={endDateToggle}
            setDateToggle={setEndDateToggle}
            selectDate={(date) => handleFilterByDate(date, 'end')}
            title={<FormattedMessage id={'organization.end'} />}
            minDate={state.chartFilters.minDate}
            monthly={state.chartFilters.monthly}
          />
          <div className={'pagination-inline'}>
            <label>
              <FormattedMessage id={'page'} />
            </label>

            <PaginationInput
              pages={pages.totalPages}
              currentPage={page}
              onChange={handleGoToPage}
            />

            <label>
              <FormattedMessage id={'ofPages'} values={{ value: pages.totalPages }} />
            </label>
          </div>
        </div>
      </div>
    </>
  );
}
function SelectSource({ title, list, onChange, type }) {
  const [toggle, setToggle] = useState(false);
  const [selectedItems, setSelectedItems] = useState(list);
  const handleCheckboxes = (e, source) => {
    let items = [...selectedItems];

    if (!e.target.checked) {
      items = items.filter((item) => item != source);
    } else {
      items = [...selectedItems, source];
    }
    setSelectedItems(items);

    onChange(items);
  };
  return (
    <div className="datepicker-ui " style={{ width: 'auto', margin: ' 0 10px' }}>
      <div className="datepicker-icon">
        <span
          className="add-on dropdown-toggle"
          onClick={() => setToggle(!toggle)}
          style={{ marginRight: 10, width: '100px' }}
        >
          {title}
        </span>
        <Animation.FadeIn
          toggle={toggle}
          setToggle={setToggle}
          style={{ position: 'absolute', left: -90 }}
        >
          <div className={'drop-down-cstm cstm-select-checkboxes'}>
            {list.map((item) => {
              return (
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxes(e, item)}
                    checked={selectedItems.indexOf(item) > -1}
                  />
                  <span>
                    <FormattedMessage id={'organization.filters.' + item} />
                  </span>
                </label>
              );
            })}
          </div>
        </Animation.FadeIn>
      </div>
    </div>
  );
}

function DatePicker({ date, dateToggle, setDateToggle, selectDate, title, minDate }) {
  const intl = useIntl();
  return (
    <div className="datepicker-ui " style={{ width: 'auto', margin: ' 0 10px' }}>
      <div className="datepicker-icon">
        <span
          className="add-on dropdown-toggle"
          onClick={() => setDateToggle(!dateToggle)}
          style={{ marginRight: 10 }}
        >
          <img src="/assets/images/date-icon.svg" alt="" style={{ width: 18 }} />{' '}
          <small>{title}</small> <small>{intl.formatDate(date)}</small>
        </span>

        <Animation.FadeIn toggle={dateToggle} setToggle={setDateToggle}>
          <div className={'date-picker-container'} style={{ right: -60 }}>
            <Calendar
              onChange={selectDate}
              value={date}
              className={'date-picker'}
              maxDate={new Date()}
              minDate={new Date(minDate)}
              view={'month'}
              onClickMonth={selectDate}
              locale={'fr'}
            />
          </div>
        </Animation.FadeIn>
      </div>
    </div>
  );
}
