import React from 'react';
import './MembersListModal.css';
import Member from './Member';
import PreLoader from '../../PreLoader/PreLoader';
import Footer from './Footer';
import useMembersList from './useMembersListModal';
import PopUp from '../../PopUp/PopUp';
import { getMembersContent, getOverlayText } from '../ColleaguesCard/ColleaguesCard.tools';
import { FormattedMessage } from 'react-intl';

export default function MembersListModal({
  organizationId,
  organizationCredits,
  organization,
  popUpToggle,
  setPopUpToggle,
}) {
  const {
    invitationsSent,
    hasEnoughLicences,
    myRef,
    isCheckAll,
    isCheck,
    list,
    loading,
    initialLoad,
    error,
    initialError,
    pendingInvitations,
    handleSelectAll,
    handleClick,
    handleAddLicences,
  } = useMembersList({
    organizationId,
    organizationCredits,
    organization,
    setPopUpToggle,
  });

  if (error || initialError) {
    return <h1>Somme Error Occurred</h1>;
  }

  const handleDone = () => {
    window.location.reload();
  };

  if (invitationsSent && invitationsSent.length > 0) {
    return (
      <PopUp toggle={true} onClose={handleDone} style={{ height: 300, width: 350 }}>
        <div id={'invitations_sent'}>
          <div id="ColleaguesCard">
            <div className={'membersAvatarContainer'}>
              {getMembersContent(invitationsSent, invitationsSent.length)}{' '}
              {getOverlayText(invitationsSent.length)}
            </div>
          </div>
          <div className="container">
            <h2>
              <FormattedMessage id={'organizations.invitationsSent'} />
            </h2>
            <p>
              <FormattedMessage
                id={'organizations.invitationsSentToNMembers'}
                values={{ value: invitationsSent.length }}
              />{' '}
            </p>
          </div>
          <div className={'btns-group'}>
            <button className={'btn btn1'} onClick={handleDone}>
              <FormattedMessage id={'organizations.done'} />
            </button>
          </div>
        </div>
      </PopUp>
    );
  }

  if (initialLoad) {
    return (
      <PopUp toggle={popUpToggle} onClose={setPopUpToggle}>
        <div id="MembersListModal">
          <div className="container">
            <PreLoader />
          </div>
        </div>
      </PopUp>
    );
  }
  return (
    <PopUp toggle={popUpToggle} onClose={setPopUpToggle}>
      <h3 className="modalHeader">
        <FormattedMessage id={'organizations.inviteYourColleagues'} />
      </h3>
      <div id="MembersListModal">
        <div className="container">
          <input type="checkbox" id="allMember" checked={isCheckAll} onChange={handleSelectAll} />
          <label htmlFor="allMember">
            <FormattedMessage id={'organizations.selectAll'} />{' '}
          </label>
          {list.map(({ _id, email, firstName, lastName, picture, type, job, lastActive }) => (
            <Member
              key={_id}
              innerRef={myRef}
              id={_id}
              email={email}
              firstName={firstName}
              lastName={lastName}
              avatar={picture}
              role={type}
              job={job}
              lastActive={lastActive}
              handleClick={handleClick}
              isChecked={isCheck.includes(_id)}
              pendingInvitations={pendingInvitations}
            />
          ))}
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src="/assets/images/loading.gif" alt="loading" width={30} height={30} />
            </div>
          )}
        </div>
      </div>
      <Footer
        total={isCheck.length}
        handleAddLicences={handleAddLicences}
        hasEnoughLicences={hasEnoughLicences}
      />
    </PopUp>
  );
}
