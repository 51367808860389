import React from 'react';
import './Footer.css';
import { FormattedMessage } from 'react-intl';

export default function Footer({ total, handleAddLicences, hasEnoughLicences }) {
  return (
    <div id="MemberListModalFooter">
      <div style={{ padding: 8 }}>{`${total} Members selected`}</div>
      {hasEnoughLicences ? (
        <button
          className="btn licensesBtn"
          style={total == 0 ? { pointerEvents: 'none', opacity: 0.6 } : {}}
          onClick={handleAddLicences}
        >
          <FormattedMessage id={'home.onboarding.sendInvite'} />
        </button>
      ) : (
        <button
          className="btn licensesBtn"
          style={total == 0 ? { pointerEvents: 'none', opacity: 0.6 } : {}}
          onClick={handleAddLicences}
        >
          + <FormattedMessage id={'organizations.addLicences'} />
        </button>
      )}
    </div>
  );
}
