import { createStore, applyMiddleware, combineReducers } from 'redux';
import { csv_search } from './reducers/csv_search';
import { labels } from './reducers/labels';
import { leads } from './reducers/leads';
import { organization } from './reducers/organization';
import { credits } from './reducers/credits';
import { notifications } from './reducers/notifications';
import { globalReducer } from './reducers/globalReducer';
import { members } from './reducers/members';
import actions from './actions';
import { thunk } from 'redux-thunk';

import { getToken } from '../services/utils';
import { getUser } from '../services/api/auth.api';
import { profile } from './reducers/profile';
import { permissions } from './reducers/permissions';
import {
  getMember,
  getMembersWithoutWorkSpace,
  getNewInvitations,
  getOrganization,
  getSubOrganizations,
} from '../services/api/organization.api';
import { getCredits, getRemaining } from '../services/api/credits.api';

const rootReducer = combineReducers({
  globalReducer,
  profile,
  labels,
  leads,
  organization,
  credits,
  notifications,
  members,
  permissions,
});

const store = createStore(rootReducer, applyMiddleware(thunk));
store.dispatch((dispatch) => {
  if (getToken()) {
    let isBlocked = false;
    getCredits()
      .then(async ({ data }) => {
        if (data.message == 'You are blocked') {
          return (isBlocked = true);
        }

        dispatch({
          type: actions.GET_CREDITS,
          payload: {
            ...data,
            isVersion: (v = 2) =>
              !data.organizationCredits
                ? true
                : data.organizationCredits &&
                  data.organizationCredits.plan &&
                  data.organizationCredits.plan.version == v,
          },
        });
      })
      .finally(() => {
        if (isBlocked)
          return dispatch({
            type: actions.GET_CREDITS,
            payload: { userBlocked: true },
          });
        Promise.all([
          getUser(),
          getOrganization(),
          getRemaining(),
          getSubOrganizations(),
          getMembersWithoutWorkSpace(),
        ]).then(
          ([
            { data: dataUser },
            { data: dataOrg },
            { data: dataRemaining },
            { data: dataSubOrg },
            { data: withoutOrg },
          ]) => {
            dataOrg.isPlanSuspended =
              dataOrg &&
              dataOrg.organization &&
              dataOrg.organization.suspension &&
              dataOrg.organization.suspension.isSuspended;

            dispatch({
              type: actions.GET_ORGANIZATION,
              payload: dataOrg,
            });
            let subOrganizations = { ...dataSubOrg, ...withoutOrg };
            dispatch({
              type: actions.GET_SUB_ORGANIZATIONS,
              payload: subOrganizations,
            });
            dispatch({
              type: actions.GET_REMAINING,
              payload: { remaining: dataRemaining },
            });

            let user = null;
            if (dataOrg.organization) {
              user = dataOrg.member;
            }
            if (dataUser.language) {
              dispatch({ type: 'SET_LANGUAGE', lang: dataUser.language });
            }
            dispatch({
              type: actions.GET_USER,
              payload: dataUser,
              permissions: user && user.permissions,
              limit: user && user.limit,
            });
          },
        );
      });
  }
});
export default store;
