import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import LeadsNav from '../common/LeadsNav/LeadsNav';
import DataNotFound from '../../../components/DataNotFound/DataNotFound';
import LeadsListFilter from '../common/LeadsList/LeadsListFilter/LeadsListFilter';
import LeadsList from '../common/LeadsList/LeadsList';
import IntegrationsPopUp from '../../Workflow/common/IntegrationsPopUp/IntegrationsPopUp';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import './LeadsPanel.css';
import { exportLeadsAsCsv, exportLeadsAsExcel, leadsFilter } from '../../../services/api/leads.api';
import { actions, LeadsContext } from '../store/leads.store';
import useFullPageLoader from '../../../components/FullPageLoader/FullPageLoader';
import { checkPermission } from '../../../services/utils/checkPermissions';
import { debounce, download_file } from '../../../services/utils/tools';
import ProfileSideModal from '../LeadsInfo/ProfileSideModal/ProfileSideModal';
import { getAccounts } from '../../../services/api/integrations.api';
import useProfile from '../LeadsInfo/Hooks/Profile.hooks';

export default function LeadsPanel(props) {
  const { checkPlanPermissions, checkMemberPermissions } = props;
  const { state, dispatch } = useContext(LeadsContext);
  const [account, setAccount] = useState(false);
  const [
    {
      loader,
      page,
      exportLeadToIntegration,
      emailToggle,
      deleteLeadErrorMessage,
      permissionToggle,
      selectedLead,
    },
    {
      handleLoader,
      setCounter,
      setPage,
      setEmailToggle,
      setExportLeadToIntegration,
      setDeleteLeadErrorMessage,
      setPermissionToggle,
      setSelectedLead,
    },
    { handleExportLeads, handleDeleteLead, handleEmailToggle, handleGoToPage },
  ] = useProfile({ props, account });

  const memberPermission = checkPermission({
    permissions: props.redux.profile.permissions,
    name: 'Leads',
    action: 'Export',
  });
  const planPermission = checkPermission({
    permissions:
      props.redux.credits.organizationCredits &&
      props.redux.credits.organizationCredits.plan.permissions,
    name: 'Leads',
    action: 'Export',
  });

  useEffect(() => {
    getAccounts().then(({ data }) => {
      if (data && data.accounts && data.accounts.gmail && data.accounts.gmail.gmailEmail)
        setAccount(true);
    });
    return () => {
      setAccount(false);
    };
  }, []);

  return (
    <div id={'leads_panel'} className={'tab-pane fade active show '}>
      {loader}
      {state.leadsLoading && (
        <div className="loader-inner-wrapper">
          <span>
            <img width={60} src="/assets/images/logo_round.png" alt="" />
            <p className="loader-icon" style={{ color: '#ccc', width: 100 }}>
              <FormattedMessage id={'loading'} />
            </p>
          </span>
        </div>
      )}
      {state.leads && state.leads.length === 0 && !state.leadsLoading ? (
        <>
          <LeadsNav
            organizationCredit={props.redux.credits && props.redux.credits.organizationCredits}
            setPage={setPage}
          />
          <DataNotFound
            style={{ paddingTop: '30px' }}
            image={'/assets/images/img_empty_no_leads.png'}
            elements={
              <div
                style={{ marginTop: '0' }}
                className="join-organization-inner join-organization-page"
              >
                <h4>
                  {state.dataForm.date || state.dataForm.search ? (
                    <FormattedMessage id={'leads.noLeadsFound'} />
                  ) : (
                    <FormattedMessage id={'leads.noLeadsYet'} />
                  )}
                </h4>
                <p className="desc">
                  <FormattedMessage id={'leads.youCanAddNewLeads'} />
                </p>
                <a
                  href={'https://linkedin.com'}
                  target={'_blank'}
                  style={{ color: 'white' }}
                  className={'btn1'}
                  data-tracking-id={'leads.noLeadsFound-goToLinkedIn'}
                  rel="noopener noreferrer nofollow"
                >
                  <FormattedMessage id={'leads.goToLinkedin'} />
                </a>
              </div>
            }
          />
        </>
      ) : (
        <>
          <LeadsNav
            organizationCredit={props.redux.credits && props.redux.credits.organizationCredits}
            setPage={setPage}
          />
          <div className="table-wrap" style={{ marginBottom: 70 }}>
            <LeadsListFilter
              organizationCredit={props.redux.credits && props.redux.credits.organizationCredits}
              page={page}
              setPage={setPage}
              handleGoToPage={handleGoToPage}
              profile={props.redux.profile}
              handleLoader={handleLoader}
              setCounter={setCounter}
              handleExportLeads={handleExportLeads}
            />
            {!state.leadsLoading && (
              <LeadsList
                organizationCredit={props.redux.credits && props.redux.credits.organizationCredits}
                profilePermissions={props.redux.profile.permissions}
                handleExportLeads={handleExportLeads}
                handleEmailToggle={handleEmailToggle}
                emailToggle={emailToggle}
                setPermissionToggle={setPermissionToggle}
                permissionToggle={permissionToggle}
                setSelectedLead={setSelectedLead}
                selectedLead={selectedLead}
                setEmailToggle={setEmailToggle}
                isVersion2={props.isVersion2}
                organization={props.organization && props.organization.organization}
                hidePersonalEmails={props.hidePersonalEmails}
              />
            )}
            {exportLeadToIntegration && (
              <IntegrationsPopUp
                exportRemaining={
                  props.remainingCredits &&
                  props.remainingCredits.organizationCredits &&
                  props.remainingCredits.organizationCredits.exportCredits
                }
                hasPermission={
                  props.redux.organizationCredit &&
                  !!props.redux.organizationCredit.plan.permissions.find(
                    (permission) =>
                      permission.name == 'Integration' && permission.actions.includes('Export'),
                  )
                }
                organizationCredits={props.redux.credits.organizationCredits}
                toggle={exportLeadToIntegration}
                setToggle={setExportLeadToIntegration}
                filters={state.dataForm}
                leads={exportLeadToIntegration}
                showToastMessage={(response, type) => {
                  type === 'success'
                    ? toast.success(response)
                    : type === 'info'
                      ? toast.info(response)
                      : toast.error(response);
                }}
                isVersion2={props.isVersion2}
              />
            )}
            {state.selectedProfile && !state.selectedProfile.isViewDetails && (
              <ProfileSideModal
                profile={state.selectedProfile}
                setModalToggle={() => {
                  dispatch({ type: actions.SELECT_LEAD, payload: null });
                  setDeleteLeadErrorMessage(null);
                }}
                handleDeleteLead={handleDeleteLead}
                handleEmailToggle={(lead) => {
                  handleEmailToggle(lead);
                  dispatch({ type: actions.SELECT_LEAD, payload: null });
                }}
                handleExportLeads={handleExportLeads}
                deleteLeadErrorMessage={deleteLeadErrorMessage}
                organization={props.redux.organization}
                profilePermissions={props.redux.profile.permissions}
                organizationPlanPermissions={
                  props.redux.credits &&
                  props.redux.credits.organizationCredits &&
                  props.redux.credits.organizationCredits.plan.permissions
                }
                organizationCredits={props.redux.credits && props.redux.credits.organizationCredits}
                checkPlanPermissions={checkPlanPermissions}
                checkMemberPermissions={checkMemberPermissions}
                isVersion2={props.isVersion2}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
