import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { InfoToolTip, ModalMessage, PreLoader } from '../../../components';
import CustomizePlanModal from '../common/CutomizePlanModal/CustomizePlanModal';
import { BillingContext } from '../store/billing.store';
import actions from '../store/billing.actions';
import { getCredits, getSubscriptionInfos, subscribe } from '../../../services/api/credits.api';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  acceptInvitation,
  createOrganization,
  getOrgAdmins,
} from '../../../services/api/organization.api';
import ChooseAdmins from '../common/ChooseAdmins/ChooseAdmins';
import PlanCard from '../common/PlanCard/PlanCard';
import './plans.css';
import ComparePlans from './ComparePlans/ComparePlans';
import queryString from 'query-string';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  capitalizeFirstLetter,
  formatSubscribeInfo,
  getExtraCreditsFromPlan,
} from '../../../services/utils/tools';
import Faq from './ComparePlans/Faq/Faq';

import ApiPlans from './ApiPlans';
import CustomizeYourPlan from './CustomizeYourPlan/CustomizeYourPlan';
import CustomizeYourPlanClient from './CustomizeYourPlanClient/CustomizeYourPlanClient';
import { PlansHeader } from '../common/PlansHeader/PlansHeader';
import { calculateExtraCreditsPrice } from '../../../services/utils/calculatePrice';

function Plans({
  credits,
  organizationCredits,
  checkPermission,
  numberOfInvitations,
  minSeats,
  setMinSeats,
  customPlan,
  setCustomPlan,
  workspaceInvitations,
  user,
  resetRedux,
  isVersion2,
  urlSegment,
}) {
  const { state, dispatch } = useContext(BillingContext);
  const [subscriptionType, setSubscriptionType] = useState('yearly');
  const [selectedPlan, selectPlan] = useState(null);
  const [permissionMessage, setPermissionMessage] = useState(null);
  const [modalToJoinWorkspace, setModalToJoinWorkspace] = useState(null);
  const [error, setError] = useState(null);
  const [downgradePlan, setDowngradePlan] = useState(null);
  const [selectAdminsToggle, setSelectAdminsToggle] = useState(false);
  const [selectedAdmins, setSelectedAdmins] = useState(null);
  const [orgAdmins, setOrgAdmins] = useState([]);
  const [comparePlansToggle, setComparePlans] = useState(false);
  const [fadeLoading, setFadeLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiWarningPopup, setApiWarningPopup] = useState(false);
  const [addCreditsToggle, setAddCreditsToggle] = useState({
    type: 'phoneCredits',
    active: false,
  });

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id && params.id.indexOf('plan_') > -1 && state.plans) {
      const plan = state.plans.find((pln) => pln.planId == params.id);
      setSubscriptionType(params.type == 'monthly' ? 'monthly' : 'yearly');
      plan.yearly = params.type == 'yearly';
      handleSelectPlan(plan);
    }
    if (params.id == 'compare') {
      setComparePlans(true);
    }
    let tempPlan = sessionStorage.getItem('_temp_pln');
    if (tempPlan) {
      tempPlan = JSON.parse(tempPlan);
      setSubscriptionType(tempPlan.subscriptionType);
      setAddCreditsToggle(
        tempPlan.addCreditsToggle || {
          type: 'phoneCredits',
          active: false,
        },
      );
      handleSelectPlan(tempPlan);
    }
  }, [state.plans]);
  useEffect(() => {
    if (
      organizationCredits &&
      organizationCredits.plan &&
      organizationCredits.plan.numberOfMonths == 1 &&
      organizationCredits.plan.planId !== '0'
    ) {
      setSubscriptionType('monthly');
    }
  }, []);
  useEffect(() => {
    const { admins } = queryString.parse(window.location.search);
    if (window.location.pathname == '/checkout' && state.organization && admins) {
      setSelectAdminsToggle(true);
    } else {
      if (window.location.pathname == '/checkout' && state.organization) {
        getSubscriptionInfos({
          numberOfSeats: organizationCredits.plan.numberOfSeats,
          numberOfMonths: organizationCredits.plan.numberOfMonths,
          planId: organizationCredits.plan.planId,
          extraCredits: getExtraCreditsFromPlan(organizationCredits.plan),
        }).then(({ data, statusCode }) => {
          dispatch({
            type: actions.GET_SUBSCRIPTION_INFOS,
            payload: {
              ...organizationCredits.plan,
              ...formatSubscribeInfo(data),
              ...(selectedAdmins ? { admins: selectedAdmins } : {}),
              isPlanSuspended: true,
            },
          });
        });
      }
    }

    if (state.subscribeInfoError) {
      toast.error(state.subscribeInfoError);
      dispatch({
        type: actions.SUBSCRIPTION_INFOS_ERROR,
        payload: null,
      });
    }

    let types = {
      phone: 'phoneCredits',
      email: 'personalEmailCredits',
      export: 'exportCredits',
    };
    if (params.id === 'credits' && state.organization) {
      handleSelectPlan({
        ...organizationCredits.plan,
        yearly: organizationCredits.plan.numberOfMonths == 12,
        phoneCreditsPerSeat: organizationCredits.plan.phoneCredits.perSeat,
        personalEmailCreditsPerSeat: organizationCredits.plan.personalEmailCredits.perSeat,
        exportCreditsPerSeat: organizationCredits.plan.exportCredits.perSeat,

        phoneCredits:
          organizationCredits.plan.phoneCredits.perSeat * organizationCredits.plan.numberOfSeats,
        personalEmailCredits:
          organizationCredits.plan.personalEmailCredits.perSeat *
          organizationCredits.plan.numberOfSeats,
        exportCredits:
          organizationCredits.plan.exportCredits.perSeat * organizationCredits.plan.numberOfSeats,
      });
      setSubscriptionType(organizationCredits.plan.numberOfMonths == 12 ? 'yearly' : 'monthly');
      if (params.type) {
        setAddCreditsToggle({
          type: types[params.type] || 'phoneCredits',
          active: true,
        });
      }
    }

    getOrgAdmins().then(({ data, statusCode }) => {
      if (statusCode == 200) {
        setOrgAdmins(
          data.admins.map((admin) => ({
            email: admin.email,
            checked: false,
          })),
        );
      }
    });
    const { addon } = queryString.parse(window.location.search);
    if (addon) {
      selectPlan(organizationCredits.plan);
      setAddCreditsToggle({
        type: 'phoneCredits',
        active: true,
      });
    }
  }, [state.organization]);
  useEffect(() => {
    if (selectedPlan) {
      // history.replace('?=customize');
      navigate('?p=customize', { replace: true });
    } else {
      //history.replace({ search: '' });
      navigate('', { replace: true });
    }
  }, [selectedPlan]);
  const createWorkSpace = async () => {
    let domainName = user.email.split('@')[1];
    domainName = domainName.split('.');
    domainName.pop();
    domainName = domainName.join('');
    const organization = {
      name: capitalizeFirstLetter(domainName) + ' Workspace',
      domains: [user.email.split('@')[1]],
    };
    const formData = new FormData();

    formData.append('picture', null);
    formData.append('organization', JSON.stringify(organization));
    return await createOrganization(formData);
  };
  const handleSelectPlan = async (plan) => {
    if (!plan) return;
    if (plan.planId == 'plan_3') setSubscriptionType('yearly');
    if (plan.planId == '0') setSubscriptionType('monthly');

    if ((plan.planId == 'plan_2' || plan.planId == 'plan_3') && !customPlan) {
      const maxOfSeats = Math.max(
        organizationCredits && organizationCredits.plan.planId != '0'
          ? organizationCredits.plan.numberOfSeats
          : state.organization
            ? state.organization.members.length + numberOfInvitations
            : 1,
        plan && plan.minSeats,
      );
      setMinSeats(maxOfSeats);
    }

    if (state.organization && !checkPermission) return setPermissionMessage(true);
    if (!state.organization && workspaceInvitations && !!workspaceInvitations.length) {
      return setModalToJoinWorkspace(plan);
    }
    let tempPlan = sessionStorage.getItem('_temp_pln');
    if (!state.organization && !tempPlan) {
      if (tempPlan) sessionStorage.removeItem('_temp_pln');
      const { data, statusCode } = await createWorkSpace();
      if (statusCode !== 200) {
        return toast.error(data.messageTranslated || data.message);
      } else {
        sessionStorage.setItem('_temp_pln', JSON.stringify({ ...plan, subscriptionType }));
        return window.location.reload();
      }
    } else {
      if (tempPlan) sessionStorage.removeItem('_temp_pln');
    }

    let currentPlan = {
      ...plan,
      isAnnual: plan.yearly,
      numberOfSeats: plan.numberOfSeats,
      country: state.checkoutForm.email
        ? state.checkoutForm.country
        : (organizationCredits &&
            organizationCredits.customer &&
            organizationCredits.customer.country) ||
          state.checkoutForm.country,
      numberOfMonths: plan.isCustom ? plan.numberOfMonths : plan.yearly ? 12 : 1,
    };

    setFadeLoading(true);
    const { data, statusCode } = await getSubscriptionInfos({
      numberOfSeats: currentPlan.numberOfSeats,
      numberOfMonths: currentPlan.numberOfMonths,
      planId: currentPlan.planId,
      extraCredits: currentPlan.extraCredits,
    });
    setFadeLoading(false);

    if (statusCode !== 200) {
      toast.error(data.messageTranslated || data.message);
      return dispatch({
        type: actions.GET_SUBSCRIPTION_INFOS,
        payload: null,
      });
    }

    const upgrade = {
      ...currentPlan,
      isPrivate: plan.isPrivate,
      automaticRenewal: !!data.nextPeriodItems.length,
      renewDate: data.renewDate,
      ...formatSubscribeInfo(data),
    };
    selectPlan(upgrade);
  };

  const handlePlanToPurchase = async (plan) => {
    setLoading(true);
    const { data, statusCode } = await getSubscriptionInfos({
      numberOfSeats: plan.numberOfSeats,
      numberOfMonths: plan.numberOfMonths,
      planId: plan.planId,
      extraCredits: plan.extraCredits,
      country: (organizationCredits.customer && organizationCredits.customer.country) || 'France',
    });
    setLoading(false);

    if (statusCode != 200) {
      toast.error(data.messageTranslated || data.message);
      return dispatch({
        type: actions.GET_SUBSCRIPTION_INFOS,
        payload: null,
      });
    }
    if (ApiPlans.indexOf(plan.planId) > -1 && !apiWarningPopup) {
      return setApiWarningPopup(plan);
    }
    if (orgAdmins.length > plan.maxAdmins && !selectedAdmins) {
      return setSelectAdminsToggle(plan);
    }
    dispatch({
      type: actions.GET_SUBSCRIPTION_INFOS,
      payload: {
        ...selectedPlan,
        ...formatSubscribeInfo(data),
        ...(selectedAdmins ? { admins: selectedAdmins } : {}),
      },
    });
  };

  const handleSeats = (users, planId) => {
    if (selectedPlan) {
      handleSelectPlan({
        ...selectedPlan,
        numberOfSeats: users,
        phoneCredits: parseInt(selectedPlan.phoneCreditsPerSeat) * users,
        personalEmailCredits: parseInt(selectedPlan.personalEmailCreditsPerSeat) * users,
        exportCredits: parseInt(selectedPlan.exportCreditsPerSeat) * users,
      });
    }
    if (customPlan) {
      const customCopy = { ...customPlan };
      customCopy.numberOfSeats = users;
      customCopy.phoneCredits = customCopy.phoneCreditsPerSeat * users;
      customCopy.personalEmailCredits = customCopy.personalEmailCreditsPerSeat * users;
      customCopy.exportCredits = customCopy.exportCreditsPerSeat * users;
      return setCustomPlan(customCopy);
    }

    const plansTemp = state.plans.map((pln) => {
      if (pln.planId == planId) {
        pln.numberOfSeats = users;
        pln.phoneCredits = users * parseInt(pln.phoneCreditsPerSeat);
        pln.personalEmailCredits = users * parseInt(pln.personalEmailCreditsPerSeat);
        pln.exportCredits = users * parseInt(pln.exportCreditsPerSeat);
      }
      return pln;
    });

    dispatch({ type: actions.GET_PLANS, payload: plansTemp });
  };
  const handleDowngrade = async () => {
    downgradePlan.isAnnual = downgradePlan.yearly;
    const { data, statusCode } = await subscribe({
      ...downgradePlan,
      deleteCoupon: !!downgradePlan.message,
    });
    if (statusCode == 200) {
      setDowngradePlan(null);
    } else {
      setError(data.messageTranslated || data.message);
    }
  };
  const handleSwitchToYearly = async (type) => {
    if (selectedPlan) {
      await handleSelectPlan({
        ...selectedPlan,
        numberOfMonths: type == 'yearly' ? 12 : 1,
        yearly: type == 'yearly',
      });
    }
    setSubscriptionType(type);
    if (organizationCredits && organizationCredits.plan.numberOfMonths == 12) selectPlan(null);
  };
  const handleJoinWorkspace = async (invitation) => {
    const { data, statusCode } = await acceptInvitation({
      organizationId: invitation.organizationId._id,
      inviter: invitation.inviter,
    });
    if (statusCode === 200) {
      window.location = '/workspace';
    } else if (statusCode >= 400) {
      toast.error(data.messageTranslated || data.message);
    }
  };
  const handleCancelJoinWorkspace = async () => {
    setModalToJoinWorkspace(false);
    let tempPlan = sessionStorage.getItem('_temp_pln');
    if (!state.organization && !tempPlan) {
      if (tempPlan) sessionStorage.removeItem('_temp_pln');
      const { data, statusCode } = await createWorkSpace();
      if (statusCode !== 200) {
        return toast.error(data.messageTranslated || data.message);
      } else {
        sessionStorage.setItem(
          '_temp_pln',
          JSON.stringify({ ...modalToJoinWorkspace, subscriptionType }),
        );
        return window.location.reload();
      }
    } else {
      if (tempPlan) sessionStorage.removeItem('_temp_pln');
    }
  };

  if ((!state.plans || loading) && window.location.pathname !== '/checkout') {
    return <PreLoader />;
  }

  if (!state.selectedPlan && window.location.pathname == '/checkout' && !selectAdminsToggle) {
    return <PreLoader />;
  }

  if (selectAdminsToggle && window.location.pathname == '/checkout') {
    const handleUnpaid = async (plan) => {
      const { data, statusCode } = await getSubscriptionInfos({
        numberOfSeats: plan.numberOfSeats,
        numberOfMonths: plan.numberOfMonths,
        planId: plan.planId,
        extraCredits: getExtraCreditsFromPlan(organizationCredits.plan),
        country: (organizationCredits.customer && organizationCredits.customer.country) || 'France',
      });
      dispatch({
        type: actions.GET_SUBSCRIPTION_INFOS,
        payload: {
          ...plan,
          ...formatSubscribeInfo(data),
          ...(selectedAdmins ? { admins: selectedAdmins } : {}),
          isPlanSuspended: true,
        },
      });
    };
    const adminsForUnpaid = [...orgAdmins];
    if (!adminsForUnpaid.find((admin) => admin.email === user.email)) {
      adminsForUnpaid.push({ checked: false, email: user.email });
    }
    return (
      <ChooseAdmins
        setToggle={() => {
          setSelectAdminsToggle(null);
          setSelectedAdmins(null);
          window.location.href = '/';
        }}
        onChange={setSelectedAdmins}
        onSubmit={() => {
          handleUnpaid(organizationCredits.plan);
        }}
        selectedAdmins={selectedAdmins}
        selectedPlan={organizationCredits.plan}
        error={error}
        setOrgAdmins={setOrgAdmins}
        orgAdmins={adminsForUnpaid}
        isUnpaid={true}
      />
    );
  }

  if (selectedPlan && organizationCredits.plan.planId === '0') {
    return (
      <>
        <CustomizeYourPlan
          selectedPlan={selectedPlan}
          setToggle={(val) => {
            selectPlan(val);
            setError(null);
          }}
          setAddCreditsToggle={setAddCreditsToggle}
          addCreditsToggle={addCreditsToggle}
          subscriptionType={subscriptionType}
          setSubscriptionType={handleSwitchToYearly}
          selectPlanToPurchase={handlePlanToPurchase}
          organizationCredits={credits.organizationCredits}
          error={error}
          numberOfInvitations={numberOfInvitations}
          plans={state.plans}
          minSeats={minSeats}
          onUserSelect={handleSeats}
          handleSeats={handleSeats}
          isVersion2={isVersion2}
          user={user}
        />
        {selectAdminsToggle && (
          <ChooseAdmins
            setToggle={() => {
              setSelectAdminsToggle(null);
              setSelectedAdmins(null);
            }}
            onChange={setSelectedAdmins}
            onSubmit={() => {
              handlePlanToPurchase(selectAdminsToggle);
            }}
            selectedAdmins={selectedAdmins}
            selectedPlan={selectAdminsToggle}
            error={error}
            setOrgAdmins={setOrgAdmins}
            orgAdmins={orgAdmins}
          />
        )}
      </>
    );
  }

  if (selectedPlan && organizationCredits.plan.planId !== '0') {
    return (
      <>
        <CustomizeYourPlanClient
          selectedPlan={selectedPlan}
          setToggle={(val) => {
            selectPlan(val);
            if (params.id && params.id.indexOf('plan_') > -1) {
              window.location.href = '/billing/plans';
            }
            setError(null);
          }}
          setAddCreditsToggle={setAddCreditsToggle}
          addCreditsToggle={addCreditsToggle}
          subscriptionType={subscriptionType}
          setSubscriptionType={handleSwitchToYearly}
          selectPlanToPurchase={handlePlanToPurchase}
          organizationCredits={credits.organizationCredits}
          error={error}
          numberOfInvitations={numberOfInvitations}
          plans={state.plans}
          minSeats={minSeats}
          onUserSelect={handleSeats}
          isVersion2={isVersion2}
          user={user}
        />
        {selectAdminsToggle && (
          <ChooseAdmins
            setToggle={() => {
              setSelectAdminsToggle(null);
              setSelectedAdmins(null);
            }}
            onChange={setSelectedAdmins}
            onSubmit={() => {
              handlePlanToPurchase(selectAdminsToggle);
            }}
            selectedAdmins={selectedAdmins}
            selectedPlan={selectAdminsToggle}
            error={error}
            setOrgAdmins={setOrgAdmins}
            orgAdmins={orgAdmins}
          />
        )}
      </>
    );
  }

  if (customPlan) {
    return (
      <div
        id="plan_tab"
        className={'custom-plan-container search-content-wrapper  tab-pane active show'}
      >
        <PlansHeader
          selectPlanDuration={handleSwitchToYearly}
          subscriptionType={subscriptionType}
          plan={selectedPlan}
        />
        {fadeLoading && <PreLoader style={{ opacity: 0.5, zIndex: 1 }} />}
        <div className={'plans_v2_container custom-plan-container'}>
          <div className={'top-gradient-line-card'} />
          <PlanCard
            plan={customPlan}
            plans={state.plans}
            selectPlan={handleSelectPlan}
            subscriptionType={subscriptionType}
            organizationCredits={credits.organizationCredits}
            handleSeats={handleSeats}
            minSeats={minSeats}
            onCompareToggle={setComparePlans}
            setAddCredits={setAddCreditsToggle}
            isCurrentSelected={true}
            selectedPlan={customPlan}
            isVersion2={isVersion2}
            isCustom
            user={user}
          />
        </div>
        <ComparePlans
          plans={state.plans}
          toggle={comparePlansToggle}
          onSelectPlan={handleSelectPlan}
          organizationCredits={credits.organizationCredits}
          subscriptionType={subscriptionType}
          customPlan={customPlan}
        />
      </div>
    );
  }

  return (
    <div id="plan_tab" className={'search-content-wrapper  tab-pane active show'}>
      <PlansHeader
        selectPlanDuration={handleSwitchToYearly}
        subscriptionType={subscriptionType}
        plan={selectedPlan}
        organizationCredits={organizationCredits}
      />
      {fadeLoading && <PreLoader style={{ opacity: 0.5, zIndex: 1 }} />}
      <div className={'plans_v2_container'}>
        {state.plans.map((plan, i) => (
          <PlanCard
            plan={plan}
            plans={state.plans}
            key={i}
            selectPlan={handleSelectPlan}
            subscriptionType={subscriptionType}
            organizationCredits={credits.organizationCredits}
            handleSeats={handleSeats}
            minSeats={minSeats}
            onCompareToggle={setComparePlans}
            setAddCredits={setAddCreditsToggle}
            isCurrentSelected={selectedPlan ? plan.planId == selectedPlan.planId : true}
            deactivatePlan={
              organizationCredits && organizationCredits.subscription && plan.planId == '0'
            }
            selectedPlan={selectedPlan}
            isVersion2={isVersion2}
            user={user}
          />
        ))}
      </div>
      {/* COMPARE PLANS */}
      <div className="compare_plan_container">
        <a
          className={'compare_plan_btn'}
          onClick={() => setComparePlans(true)}
          href={'#compare-plans-scroll'}
        >
          <FormattedMessage id={'billing.plan.comparePlans'} />
          <img src="/assets/images/arrow_up.svg" alt="arrow up" />
        </a>
      </div>
      <ComparePlans
        plans={state.plans}
        toggle={comparePlansToggle}
        onSelectPlan={handleSelectPlan}
        organizationCredits={credits.organizationCredits}
        subscriptionType={subscriptionType}
      />
      <Faq />
      {selectAdminsToggle && (
        <ChooseAdmins
          setToggle={() => {
            setSelectAdminsToggle(null);
            setSelectedAdmins(null);
          }}
          onChange={setSelectedAdmins}
          onSubmit={() => {
            handlePlanToPurchase(selectAdminsToggle);
          }}
          selectedAdmins={selectedAdmins}
          selectedPlan={selectAdminsToggle}
          error={error}
          setOrgAdmins={setOrgAdmins}
          orgAdmins={orgAdmins}
        />
      )}
      {apiWarningPopup && (
        <ModalMessage
          toggle={apiWarningPopup}
          setToggle={() => {
            setApiWarningPopup(false);
          }}
          textElement={
            <div style={{ marginTop: 10 }}>
              <FormattedMessage
                id={'billing.apiWarning'}
                values={{ strong: (chunk) => <strong> {chunk} </strong> }}
              />
            </div>
          }
          action={<FormattedMessage id={'billing.ContinueAnyWAy'} />}
          cancel={<FormattedMessage id={'billing.contactSupport'} />}
          handleAction={() => handlePlanToPurchase(apiWarningPopup)}
          cancelLink={'mailto:contact@kaspr.io'}
        />
      )}
      {downgradePlan && (
        <ModalMessage
          toggle={downgradePlan}
          setToggle={() => {
            setDowngradePlan(false);
          }}
          title={'Downgrade to ' + downgradePlan.name}
          textElement={
            <>
              <br />
              <strong>
                <FormattedMessage id={'billing.yourDowngradeWillTakePlace'} />
              </strong>
              <br />
              <p>
                {downgradePlan.yearly ? (
                  <FormattedMessage id={'billing.nextYearYouWillBeChanged'} />
                ) : (
                  <FormattedMessage id={'billing.nextMonthYouWillBeChanged'} />
                )}{' '}
                <strong> {downgradePlan.renewDate + '€'}</strong>
              </p>
              {downgradePlan.message && (
                <div style={{ width: '65%', margin: '0 auto' }}>
                  <p style={{ color: 'coral', margin: 0 }}>You’re about to lose your coupon ! </p>
                  <small style={{ color: 'coral', fontSize: 11 }}>
                    {downgradePlan.message}
                  </small>{' '}
                </div>
              )}
            </>
          }
          action={<FormattedMessage id={'billing.proceed'} />}
          handleAction={handleDowngrade}
          error={error}
        />
      )}
      {modalToJoinWorkspace && (
        <ModalMessage
          toggle={modalToJoinWorkspace}
          setToggle={handleCancelJoinWorkspace}
          title={<FormattedMessage id={'organization.workSpaceInvitation'} />}
          textElement={
            <div className={'invitations-received'}>
              <div>
                <img
                  src={workspaceInvitations[0].inviterPicture || '/assets/images/ic_profile.png'}
                  alt=""
                />
                <FormattedMessage
                  id={'home.invitedYouOn'}
                  values={{
                    inviter: <strong> {workspaceInvitations[0].inviter} </strong>,
                    orgName: <strong> '{workspaceInvitations[0].organizationId.name}' </strong>,
                  }}
                />
              </div>
            </div>
          }
          action={<FormattedMessage id={'organization.join'} />}
          handleAction={() => handleJoinWorkspace(workspaceInvitations[0])}
          error={error}
        />
      )}
    </div>
  );
}

function CustomPlan({ intl }) {
  return (
    <Col md={3}>
      <div className="subcription-plan-ui" style={{ background: '#F4F6FF' }}>
        <h4 className={'title '}>
          <FormattedMessage id={'billing.custom'} />
        </h4>
        <p className="subtitle">
          <FormattedMessage id={'billing.custom.forOrganizationsSeeking'} />
        </p>

        <a
          className="btn2"
          target={'_blank'}
          href={'https://kaspr.io/contact/'}
          style={{
            background: '#fff',
            border: '1px solid #3b53d1',
            marginTop: '80px',
          }}
        >
          <FormattedMessage id={'billing.contactUs'} />
        </a>
        <ul className="permissions">
          {[
            intl.formatMessage({ id: 'billing.contactSearch' }),
            intl.formatMessage({ id: 'billing.contactManagement' }),
            intl.formatMessage({ id: 'billing.exportingContact' }),
            intl.formatMessage({ id: 'billing.crmIntegration' }),
            intl.formatMessage({ id: 'billing.salesAutomation' }),
            intl.formatMessage({ id: 'billing.statisticsAndPerformance' }),
            intl.formatMessage({ id: 'billing.24x7CustomerSupport' }),
            intl.formatMessage({ id: 'billing.teamManagement' }),
            intl.formatMessage({ id: 'billing.apiAccess' }),
          ].map((permission, i) => (
            <li key={i}>
              {permission}{' '}
              {permission == 'Sales Automation' && (
                <InfoToolTip
                  text={intl.formatMessage({
                    id: 'billing.salesAutomationTextInfo',
                  })}
                />
              )}{' '}
            </li>
          ))}
        </ul>
      </div>
    </Col>
  );
}
CustomPlan = injectIntl(CustomPlan);

export default Plans;
