import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import './App.media.css';
import * as Page from './container';
import { ErrorBar, PreLoader, SideNav } from './components';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import actions from './redux/actions';
import { getToken } from './services/utils';
import AuthComplete from './container/Auth/AuthServ/AuthComplete';
import { getCredits, getRemaining } from './services/api/credits.api';
import { CHROME_STORE, EXTENSION_ID, IS_PROD, SOURCE_BUSTER_CONFIG } from './constants';
import { getNotifications } from './services/api/notifications.api';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import TrackingEvents from './services/TrackingEvents/TrackingEvents';
import LegacyPopup from './components/LegacyPopup/LegacyPopup';
import 'react-toastify/dist/ReactToastify.css';
import { getOrganization, updateMemberInfos } from './services/api/organization.api';
import { getUser } from './services/api/auth.api';
import reduxActions from './redux/actions';

import sbjs from 'sourcebuster';
import cookie from 'react-cookies';

function App(reduxProps) {
  const [barError, setBarrError] = useState(false);
  const intl = useIntl();
  useEffect(() => {
    let sb_utm = cookie.load('sbjs_first', { path: '/', domain: 'kaspr.io' });
    if (!sb_utm) sbjs.init(SOURCE_BUSTER_CONFIG);

    if (getToken()) {
      getNotifications().then(({ data }) => {
        if (data.banner) {
          setTimeout(() => {
            setBarrError(data.banner);
          }, 1000);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (
      reduxProps.redux.credits.organizationCredits &&
      reduxProps.redux.credits.organizationCredits.disabled
    ) {
      setBarrError({
        descriptionText: intl.formatMessage({
          id: 'permissions.weHaveAProblemWithYourPurchase',
        }),
      });
    }
  }, [reduxProps.redux.credits]);
  const reactIntl = useIntl();
  useEffect(() => {
    let { redux, intl } = reduxProps;
    if (!intl) intl = reactIntl;
    if (
      redux.profile.steps &&
      window.location.href.indexOf('welcome') == -1 &&
      window.location.href.indexOf('emailvalidation') == -1
    ) {
      const pluginInstallation = redux.profile.steps.find((stp) => stp.id === 'pluginInstallation');
      const linkedinValidation = redux.profile.steps.find((stp) => stp.id === 'linkedinValidation');

      if (pluginInstallation && !pluginInstallation.done) {
        return setBarrError({
          descriptionText: intl.formatMessage({
            id: 'permissions.ifYouWantToUseKasprPleaseDownloadThePlugin',
          }),
          link: CHROME_STORE,
          buttonText: (
            <>
              plugin
              <img width={13} src="/assets/images/download-solid.svg" alt="" />
            </>
          ),
          type: 'error',
        });
      }
      if (linkedinValidation && !linkedinValidation.done) {
        return setBarrError({
          descriptionText: intl.formatMessage({
            id: 'permissions.youHaveToConnectYourAccountWithLinkedin',
          }),
          link: 'https://www.linkedin.com/feed/?synchro=kaspr&isFirstKasprSynchro=true',
          buttonText: (
            <>
              <FormattedMessage id={'connect'} />
              <img width={13} src="/assets/images/ic-linkedin.png" alt="" />
            </>
          ),
          type: 'error',
        });
      }
    }
    if (window.chrome && window.chrome.runtime && window.chrome.runtime.sendMessage && IS_PROD) {
      window.chrome.runtime.sendMessage(
        EXTENSION_ID,
        {
          action: 'isKasprExtensionInstalled',
        },
        async (response, err) => {
          if (response === undefined) {
            return setBarrError({
              descriptionText: intl.formatMessage({
                id: 'permissions.ifYouWantToUseKasprPleaseDownloadThePlugin',
              }),
              link: CHROME_STORE,
              buttonText: (
                <>
                  LinkedIn Extension{' '}
                  <img width={13} src="/assets/images/download-solid.svg" alt="" />
                </>
              ),
              type: 'error',
            });
          }
        },
      );
    }
    if (reduxProps.redux.profile && window.intercomeGetUser) {
      window.intercomeGetUser(reduxProps.redux.profile);
    }
  }, [reduxProps.redux.profile]);
  if (!getToken()) {
    return (
      <TrackingEvents onTrack={console.log}>
        <BrowserRouter>
          <Routes>
            <Route path={'/signin'} element={<Page.Signin />} />
            <Route path={'/signup'} element={<Page.Signup />} />
            <Route path={'/signup/confirm'} element={<Page.SignupFromLinkParams />} />
            <Route path={'/signup/:email'} element={<Page.Signup />} />
            <Route path={'/forgot'} element={<Page.ForgotPasswd />} />
            <Route path={'/reset'} element={<Page.ResetPasswd />} />

            <Route path={'/optout'} element={<Page.OptOut />} />
            <Route path={'/optoutAutomatic'} element={<Page.OptOutFromEmail />} />

            <Route path={'/checkdevice'} element={<Page.ChekDevice />} />
            <Route path={'*'} element={<Navigate to="/signin" replace />} />
          </Routes>
        </BrowserRouter>
      </TrackingEvents>
    );
  }
  const redux = { ...reduxProps, ...reduxProps.redux };
  delete redux.redux;
  const isAdminWithSubscription = () => {
    const member = redux.organization && redux.organization.member;
    if (!member) return true;
    if (member && member.type !== 'member') return true;
    /*  return !(
      member &&
      member.type == "member" &&
      redux.credits.organizationCredit &&
      redux.credits.organizationCredits.subscription
    );*/
  };
  if (redux.credits.userBlocked) {
    return <Page.UserBlocked />;
  }
  if (!redux.profile.email) {
    return <PreLoader />;
  }

  if (redux.credits && !redux.credits.personalCredits && redux.profile.step === 0) {
    return <PreLoader />;
  }
  if (redux.profile.nextStep === -1 && !redux.credits.remaining) {
    return <PreLoader />;
  }
  const nextStep = redux.profile.nextStep;
  const showLegacyPopup =
    (((nextStep === -1 || nextStep > 2) &&
      redux.credits.organizationCredits &&
      window.location.pathname.indexOf('billing') === -1 &&
      redux.credits.showPopUp) ||
      window.location.href.indexOf('legacy') > -1) &&
    redux.credits &&
    redux.credits.isVersion(1);

  if (redux.organization.isPlanSuspended) {
    return (
      <TrackingEvents>
        <BrowserRouter>
          <Routes>
            <Route path={'/'} element={<Page.Unpaid {...redux} />} />
            <Route path={'/notify'} element={<Page.NotifySuccess redux={redux} />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </TrackingEvents>
    );
  }

  return (
    <TrackingEvents>
      <BrowserRouter>
        {showLegacyPopup && (
          <LegacyPopup.Choice
            organizationCredits={redux.credits.organizationCredits}
            currentPlan={redux.credits.organizationCredits.plan}
          />
        )}
        {barError && <ErrorBar info={barError} onHide={setBarrError} />}
        <div className="App">
          {(nextStep === -1 || nextStep > 2) && (
            <Routes>
              <Route path={'/home'} element={<Page.Home redux={redux} />} />
              <Route path={'/onboarding'} element={<Page.Home redux={redux} />} />
              <Route path={'/onboarding/:step'} element={<Page.Home redux={redux} />} />
              <Route path={'/bulk-enrichment'} element={<Page.BulkEnrichment redux={redux} />} />
              <Route
                path={'/bulk-enrichment/files'}
                element={<Page.BulkEnrichment redux={redux} urlSegment={'files'} />}
              />
              <Route
                path={'/bulk-enrichment/file-upload'}
                element={<Page.BulkEnrichment redux={redux} urlSegment={'file-upload'} />}
              />
              <Route path={'leads'} element={<Page.Leads redux={redux} urlSegment={'leads'} />} />
              <Route
                path={'leads/:tabId'}
                element={<Page.Leads redux={redux} urlSegment={'waitinglist'} />}
              />
              <Route path={'/tasks'} element={<Page.Tasks redux={redux} />} />
              <Route path={'/lead/:leadId'} element={<Page.Leads redux={redux} />} />
              <Route path={'/workflow'} element={<Page.Workflow redux={redux} />} />
              <Route path={'/workflow/:id'} element={<Page.Workflow redux={redux} />} />
              <Route
                path={'/createworkflow/:name/:integration'}
                element={<Page.CreateWorkflow redux={redux} />}
              />
              <Route
                path={'/createworkflow/:name'}
                element={<Page.CreateWorkflow redux={redux} />}
              />
              <Route path={'/organization'} element={<Page.Organization redux={redux} />} />
              <Route
                path={'/organization/createjoin'}
                element={<Page.CreateOrganization redux={redux} />}
              />
              <Route path={'/workspace'} element={<Page.Organization redux={redux} />} />
              <Route
                path={'/workspace/createjoin'}
                element={<Page.CreateOrganization redux={redux} />}
              />
              <Route
                path={'/workspace/members'}
                element={<Page.Organization redux={redux} urlSegment={'members'} />}
              />
              <Route
                path={'/workspace/statistics'}
                element={<Page.Organization redux={redux} urlSegment={'statistics'} />}
              />
              <Route
                path={'/workspace/activity'}
                element={<Page.Organization redux={redux} urlSegment={'activity'} />}
              />
              <Route
                path={'/workspace/settings'}
                element={<Page.Organization redux={redux} urlSegment={'settings'} />}
              />
              <Route path={'/settings'} element={<Page.Settings redux={redux} />} />

              <Route
                path={'/settings/profile'}
                element={<Page.Settings redux={redux} urlSegment={'profile'} />}
              />
              <Route
                path={'/settings/workspace'}
                element={<Page.Settings redux={redux} urlSegment={'workspace'} />}
              />
              <Route
                path={'/settings/integration'}
                element={<Page.Settings redux={redux} urlSegment={'integration'} />}
              />
              <Route
                path={'/settings/api'}
                element={<Page.Settings redux={redux} urlSegment={'api'} />}
              />
              {isAdminWithSubscription() && (
                <Route path={'/billing'} element={<Page.Billing redux={redux} />} />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/info'}
                  element={<Page.Billing redux={redux} urlSegment={'info'} />}
                />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/info/:id'}
                  element={<Page.Billing redux={redux} urlSegment={'info'} />}
                />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/plans'}
                  element={<Page.Billing redux={redux} urlSegment={'plans'} />}
                />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/plans/:id'}
                  element={<Page.Billing redux={redux} urlSegment={'plans'} />}
                />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/plans/:id/:type'}
                  element={<Page.Billing redux={redux} urlSegment={'plans'} />}
                />
              )}
              <Route path="/socialAuth" component={AuthComplete} />
              <Route path={'/optout'} element={<Page.OptOut />} />
              <Route path={'/optoutAutomatic'} element={<Page.OptOutFromEmail />} />
              <Route path="*" element={<Navigate to="/home" replace />} />
            </Routes>
          )}
          <Routes>
            {nextStep === 0 && (
              <Route
                path={'/emailvalidation'}
                element={<Page.SignupCodeConfirmation redux={redux} />}
              />
            )}
            {nextStep < 3 && (
              <Route path={'/welcome'} element={<Page.Onboarding redux={redux} />} />
            )}

            <Route
              path="*"
              element={
                nextStep === 0 ? (
                  <Navigate to="emailvalidation" replace />
                ) : nextStep === 1 ? (
                  <Navigate to="welcome" replace />
                ) : nextStep === 2 ? (
                  <Navigate to="welcome" replace />
                ) : null
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    </TrackingEvents>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setNavBarToggle: (navToggle) => {
    dispatch({ type: actions.NAVBAR_TOGGLE, navToggle });
  },
  resetCredits: () => {
    getCredits().then(({ data, statusCode }) => {
      dispatch({ type: actions.GET_CREDITS, payload: data });
    });
  },
  resetRedux: async () => {
    const [{ data: dataCredits }, { data: dataUser }, { data: dataOrg }, { data: dataRemaining }] =
      await Promise.all([getCredits(), getUser(), getOrganization(), getRemaining()]);
    dispatch({
      type: reduxActions.GET_ORGANIZATION,
      payload: dataOrg,
    });

    dispatch({
      type: reduxActions.GET_CREDITS,
      payload: dataCredits,
    });
    dispatch({
      type: reduxActions.GET_USER,
      payload: dataUser,
    });
    return dataOrg;
  },
});

const mapStateToProps = (state) => {
  return { redux: state };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
