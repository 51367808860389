import React, { useState, useEffect, useContext } from 'react';
import { ModalMessage, PreLoader, Pagination, Tooltip, Permissions } from '../../../components';
import WorkflowContentWrapper from '../common/WorkflowContentWrapper/WorkflowContentWrapper';
import CreateWkPopUp from '../common/CreateWkPopUp/CreateWkPopUp';
import {
  createWorkflow,
  deleteWorkflow,
  getWorkflow,
  getWorkflows,
  updateWorkflowActivationState,
} from '../../../services/api/workflows.api';
import { actions, WorkflowContext } from '../store/workflow.store';
import IntegrationsPopUp from '../common/IntegrationsPopUp/IntegrationsPopUp';
import { useRef } from 'react';
import DataNotFound from '../../../components/DataNotFound/DataNotFound';
import DeleteWorkflowModal from '../common/DeleteWorkflowModal/DeleteWorkflowModal';
import { NavLink } from 'react-router-dom';
import { checkPermission } from '../../../services/utils';
import { toast, ToastContainer } from 'react-toastify';
import { OverlayTrigger } from 'react-bootstrap';
import { checkIsPlanLimited } from '../../../services/utils/checkPermissions';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import WorkflowTutorial from './WorkflowTutorial/WorkflowTutorial';
import MyWorkflowsFilters from './MyWorfklowsFilters/MyWorkflowsFilters';
import { usePermissions } from '../../../components/Permissions/permissions.hooks';
import './MyWorkflows.css';

export default function MyWorkflows({
  navToggle,
  organization: org,
  profile,
  organizationCredit,
  isVersion2,
}) {
  const { state, dispatch } = useContext(WorkflowContext);
  const [modalToggle, setModalToggle] = useState(false);
  const [integrationsToggle, goToIntegrations] = useState(false);
  const [page, setPage] = useState(1);
  const [organization, setOrganization] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [wkTutorialToggle, setWkTutorialToggle] = useState(false);
  const [checkPlanPermissions, checkMemberPermissions] = usePermissions(
    organizationCredit && organizationCredit.plan.permissions,
    profile.permissions,
    'Workflow',
  );
  useEffect(() => {
    if (org) {
      setOrganization(org.organization);
    } else {
      setOrganization(false);
    }
    getWorkflows().then(({ data, statusCode }) => {
      if (statusCode === 200) {
        dispatch({ type: actions.GET_WORKFLOWS, payload: data });
      } else {
        setErrorMessage(data.messageTranslated || data.message);
      }
    });
  }, [org]);

  const handleGoToPage = async (pg) => {
    const { data, statusCode } = await getWorkflows({
      page: pg - 1,
      filters: state.workflowsFilters.filterBy ? { type: [state.workflowsFilters.filterBy] } : {},
      sortOrder: state.workflowsFilters.sortBy || null,
    });
    dispatch({ type: actions.GET_WORKFLOWS, payload: data });
    setPage(pg);
  };
  if (!checkPlanPermissions('View')) {
    return (
      <WorkflowContentWrapper>
        <Permissions.View title={'title'} description={'desc'} img={'workflows-bg.png'} />
      </WorkflowContentWrapper>
    );
  }
  if (!checkMemberPermissions('View')) {
    return (
      <WorkflowContentWrapper>
        <Permissions.View title={'title'} description={'desc'} img={'workflows-bg.png'} />
      </WorkflowContentWrapper>
    );
  }
  if (!organization) {
    return (
      <WorkflowContentWrapper>
        <DataNotFound
          image={'/assets/images/img_empty_no_workflows.png'}
          elements={
            <div
              style={{ marginTop: '0' }}
              className="join-organization-inner join-organization-page"
            >
              <h4>
                <FormattedMessage id={'organization.youHavetoCreateAnOrganization'} />{' '}
              </h4>
              <p className="desc">
                <FormattedMessage id={'organization.youHavetoCreateAnOrganization.text'} />
              </p>
              <button className={'btn1'}>
                <NavLink to={'/organization'} style={{ color: 'white' }}>
                  <FormattedMessage id={'organization.createOrganization.text'} />
                </NavLink>
              </button>
            </div>
          }
        />
      </WorkflowContentWrapper>
    );
  }

  if (!state.workflows) {
    return <PreLoader />;
  }

  if (state.workflows && state.workflows.pages && state.workflows.pages.totalWorkflows == 0) {
    return (
      <WorkflowContentWrapper navToggle={navToggle}>
        {state.workflowsFilters && (
          <div className="table-wrap workflow-page-wrapper">
            <MyWorkflowsFilters
              page={page}
              handleGoToPage={handleGoToPage}
              setModalToggle={setModalToggle}
              checkPlanPermissions={checkPlanPermissions}
              checkMemberPermissions={checkMemberPermissions}
            />
          </div>
        )}
        <DataNotFound
          image={'/assets/images/img_empty_no_workflows.png'}
          style={{ paddingTop: '20px' }}
          elements={
            <div
              style={{ marginTop: '0' }}
              className="join-organization-inner join-organization-page"
            >
              <h4>
                <FormattedMessage id={'workflow.noWorkflowsYet'} />{' '}
              </h4>
              <p className="desc">
                <FormattedMessage id={'workflow.youCanCreateAnAutomatedWorkflow'} />
              </p>
              {!checkPlanPermissions('Edit') || !checkMemberPermissions('Edit') ? (
                <Permissions.CallToAction
                  isButton={true}
                  isPlan={!checkPlanPermissions('Edit')}
                  toolTipStyle={{ width: 250 }}
                >
                  <a className="border-plus-icon">
                    <img src="/assets/images/ic_insert_variable-w.svg" alt="" />
                  </a>
                  <FormattedMessage id={'workflow.createWorkflow'} />
                </Permissions.CallToAction>
              ) : (
                <button className="btn1" onClick={() => setModalToggle(true)}>
                  <a className="border-plus-icon">
                    <img src="/assets/images/ic_insert_variable-w.svg" alt="" />
                  </a>
                  <FormattedMessage id={'workflow.createWorkflow'} />
                </button>
              )}
            </div>
          }
        />
        {modalToggle && (
          <CreateWkPopUp
            toggle={modalToggle}
            setToggle={setModalToggle}
            goToIntegrations={() => {
              goToIntegrations(true);
              setModalToggle(false);
            }}
          />
        )}
        {integrationsToggle && (
          <IntegrationsPopUp
            toggle={integrationsToggle}
            setToggle={() => goToIntegrations(false)}
            organizationCredits={organizationCredit}
            isVersion2={isVersion2}
          />
        )}
      </WorkflowContentWrapper>
    );
  }

  return (
    <WorkflowContentWrapper navToggle={navToggle}>
      <div className="table-wrap workflow-page-wrapper">
        <MyWorkflowsFilters
          page={page}
          handleGoToPage={handleGoToPage}
          setModalToggle={setModalToggle}
          checkPlanPermissions={checkPlanPermissions}
          checkMemberPermissions={checkMemberPermissions}
        />
        <WorkflowsList
          workflows={state.workflows.workflows}
          profile={profile}
          organizationCredit={organizationCredit}
          currentPage={page}
          checkPlanPermissions={checkPlanPermissions}
          checkMemberPermissions={checkMemberPermissions}
        />
      </div>
      <Pagination
        pages={state.workflows.pages.pages}
        currentPage={page}
        goToPage={handleGoToPage}
        bottom
      />
      {modalToggle && (
        <CreateWkPopUp
          toggle={modalToggle}
          setToggle={setModalToggle}
          goToIntegrations={() => {
            goToIntegrations(true);
            setModalToggle(false);
          }}
        />
      )}

      {integrationsToggle && (
        <IntegrationsPopUp
          toggle={integrationsToggle}
          setToggle={() => goToIntegrations(false)}
          organizationCredits={organizationCredit}
          isVersion2={isVersion2}
        />
      )}
    </WorkflowContentWrapper>
  );
}

function WorkflowsList({
  workflows,
  profile,
  organizationCredit,
  currentPage,
  checkPlanPermissions,
  checkMemberPermissions,
}) {
  const { state, dispatch } = useContext(WorkflowContext);
  const [workflowToRemove, setWorkflowToRemove] = useState(null);
  const [workflowToClone, setWorkflowToClone] = useState(null);
  const intl = useIntl();
  const handleSelectWorkflow = async (wk) => {
    dispatch({ type: actions.SELECTED_TEMPLATE, payload: null });
    dispatch({ type: actions.GET_PERFORMANCE, payload: null });
    dispatch({ type: actions.GET_PROFILES, payload: null });
    window.location = '/workflow/' + wk._id;
  };
  const handleWorkflowActivation = async (e, workflow) => {
    if (
      checkPermission({
        permissions: profile.permissions,
        name: 'Workflow',
        action: 'Edit',
      })
    ) {
      workflow.active = e.target.checked;
      const { data, statusCode } = await updateWorkflowActivationState({
        _id: workflow._id,
        active: e.target.checked,
      });
      if (statusCode === 200) {
        const { data: wkData, statusCode: wkStatus } = await getWorkflows({
          page: currentPage - 1,
        });
        if (wkStatus === 200) {
          dispatch({ type: actions.GET_WORKFLOWS, payload: wkData });
          setWorkflowToRemove(false);
        }
      }
    } else {
      toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }
  };
  const handleRemoveWorfklow = async () => {
    if (
      checkPermission({
        permissions: profile.permissions,
        name: 'Workflow',
        action: 'Edit',
      })
    ) {
      const { data, statusCode } = await deleteWorkflow(workflowToRemove._id);
      if (statusCode === 200) {
        const { data: wkData, statusCode: wkStatus } = await getWorkflows();
        if (wkStatus === 200) {
          dispatch({ type: actions.GET_WORKFLOWS, payload: wkData });
          setWorkflowToRemove(false);
        }
      }
    } else {
      setWorkflowToRemove(false);
      toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }
  };

  const handleCloneWorfklow = async () => {
    if (
      checkPermission({
        permissions: profile.permissions,
        name: 'Workflow',
        action: 'Edit',
      })
    ) {
      const { data: retrieved, statusCode: retrievedStatus } = await getWorkflow(
        workflowToClone._id,
      );
      if (retrievedStatus == 200) {
        retrieved.workflow.name = intl.formatMessage({ id: 'cloneOf' }) + workflowToClone.name;
        retrieved.workflow.blocks = retrieved.workflow.blocks.filter(
          (block) => block.type !== 'flow_input',
        );
        retrieved.workflow.blocks = retrieved.workflow.blocks.map((block) => {
          delete block._id;
          return block;
        });
        const { data, statusCode } = await createWorkflow(retrieved.workflow);
        if (statusCode === 200) {
          setWorkflowToClone(false);
          const { data: wkData, statusCode: wkStatus } = await getWorkflows();
          if (wkStatus === 200) {
            dispatch({ type: actions.GET_WORKFLOWS, payload: wkData });
            setWorkflowToRemove(false);
          }
        }
      }
    } else {
      setWorkflowToClone(false);
      toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }
  };
  return (
    <div className="table-content">
      <table width="100%">
        <thead>
          <tr>
            <th />
            <th>
              <FormattedMessage id={'workflow.list.name'} />
            </th>
            <th>
              <FormattedMessage id={'workflow.list.type'} />
            </th>
            <th>
              <FormattedMessage id={'workflow.list.totalProfiles'} />
            </th>
            <th>
              <FormattedMessage id={'workflow.list.activeProfiles'} />
            </th>
            <th>
              <FormattedMessage id={'workflow.list.status'} />
            </th>
          </tr>
        </thead>
        <tbody>
          {workflows.map((workflow, i) => (
            <WorkflowItem
              key={i}
              workflow={workflow}
              onSelectWorkflow={handleSelectWorkflow}
              removeWorkflow={setWorkflowToRemove}
              setWorkflowActivation={handleWorkflowActivation}
              cloneWorkflow={setWorkflowToClone}
              organizationCredit={organizationCredit}
              checkPlanPermissions={checkPlanPermissions}
              checkMemberPermissions={checkMemberPermissions}
            />
          ))}

          {workflowToRemove && (
            <DeleteWorkflowModal
              toggle={workflowToRemove}
              setWorkflowToRemove={setWorkflowToRemove}
              handleRemoveWorfklow={handleRemoveWorfklow}
            />
          )}
          {workflowToClone && (
            <ModalMessage
              title={
                <>
                  <FormattedMessage id={'clone'} /> {workflowToClone.name}{' '}
                </>
              }
              textElement={
                <p>
                  <strong>
                    <FormattedMessage id={'workflow.list.areYouSureYouWantToClone'} />{' '}
                    {workflowToClone.name} workflow ?
                  </strong>
                  <br />
                  <FormattedMessage id={'workflow.list.pleaseClickToConfirmTheClone'} />{' '}
                </p>
              }
              action={<FormattedMessage id={'confirm'} />}
              toggle={workflowToClone}
              setToggle={() => setWorkflowToClone(false)}
              handleAction={handleCloneWorfklow}
            />
          )}
        </tbody>
      </table>
      <ToastContainer position="bottom-right" style={{ top: 100 }} />
    </div>
  );
}
WorkflowsList = injectIntl(WorkflowsList);
function WorkflowItem({
  workflow,
  onSelectWorkflow,
  removeWorkflow,
  setWorkflowActivation,
  cloneWorkflow,
  organizationCredit,
  checkMemberPermissions,
  checkPlanPermissions,
}) {
  const delete_ref = useRef(null);
  const clone_ref = useRef(null);
  const status_ref = useRef(null);
  const planPermission = checkIsPlanLimited({
    permissions: organizationCredit && organizationCredit.plan.permissions,
    name: 'Workflow',
  });
  const handleSelectWorkflow = (e) => {
    if (delete_ref.current.contains(e.target)) {
      return removeWorkflow(workflow);
    }
    if (clone_ref.current.contains(e.target)) {
      return cloneWorkflow(workflow);
    }
    if (status_ref.current.contains(e.target)) {
      return setWorkflowActivation(e, workflow);
    }
    onSelectWorkflow(workflow);
  };
  return (
    <tr
      id="wk-list"
      onClick={workflow.type === 'linkedin' ? null : handleSelectWorkflow}
      style={{
        cursor: workflow.type === 'linkedin' ? 'default' : 'pointer',
        backgroundColor: workflow.type === 'linkedin' ? '#F7F7F7' : 'inherit',
        opacity: workflow.type === 'linkedin' ? 0.7 : 'inherit',
      }}
    >
      <td />
      <td>
        <div className="username">
          <p>{workflow.name}</p>
          {workflow.type == 'linkedin' && (
            <OverlayTrigger
              key={'linkedin-wk'}
              placement={'top'}
              overlay={(props) =>
                Tooltip({
                  ...props,
                  data: <FormattedMessage id={'workflow.list.onlyTheCreatorCanUseThisWk'} />,
                })
              }
            >
              <img
                src="/assets/images/lockBlack.png"
                alt=""
                style={{ width: 10, marginLeft: 10 }}
              />
            </OverlayTrigger>
          )}
        </div>
        <div>
          <small>
            <FormattedMessage id={'workflow.list.by'} />: {workflow.creator}
          </small>
          {workflow.type === 'linkedin' && (
            <div className="linkedin-workflow-container">
              <span className="linkedin-span-container">
                <div className="linkedin-flex-container">
                  <i className="fas fa-exclamation-triangle linkedin-icon"></i>{' '}
                  <div className="linkedin-text">
                    <FormattedMessage id="workflows.currentlyUnavailable" />
                  </div>
                </div>
              </span>
            </div>
          )}
        </div>
      </td>
      <td>
        <span className={'tag ' + workflow.type + '-tag'}>
          {<FormattedMessage id={'workflow.' + workflow.type} />}
          {workflow.type == 'linkedin' && (
            <span
              className="beta-badge"
              style={{
                color: '#fff',
                marginBottom: 2,
                marginLeft: 8,
                padding: '1px 5px',
                fontSize: 10,
              }}
            >
              BETA
            </span>
          )}
        </span>
      </td>
      <td>{workflow.totalContacts}</td>
      <td>{workflow.activeContacts}</td>
      <td>
        <div className="actions-btn" ref={status_ref}>
          <div className="switch-btn">
            {planPermission || !checkPlanPermissions('Edit') || !checkMemberPermissions('Edit') ? (
              <Permissions.CallToAction
                withoutIcon={true}
                isPlan={planPermission || !checkPlanPermissions('Edit')}
              >
                <input
                  type="checkbox"
                  className="switch"
                  disabled={workflow.type === 'linkedin'}
                  checked={workflow.type === 'linkedin' ? false : workflow.active}
                  onChange={(e) => setWorkflowActivation(e, workflow)}
                />
              </Permissions.CallToAction>
            ) : (
              <input
                type="checkbox"
                className="switch"
                disabled={workflow.type === 'linkedin'}
                checked={workflow.type === 'linkedin' ? false : workflow.active}
                onChange={(e) => setWorkflowActivation(e, workflow)}
              />
            )}
          </div>
          {!checkPlanPermissions('Edit') || !checkMemberPermissions('Edit') ? (
            <Permissions.CallToAction
              withoutIcon={true}
              isPlan={!checkPlanPermissions('Edit')}
              toolTipPosition={'left'}
              toolTipStyle={{ left: '50%' }}
            >
              <div className="merge-icons" ref={clone_ref}>
                <a href="#">
                  <img src="/assets/images/ic_copy.svg" alt="" />
                </a>
              </div>
            </Permissions.CallToAction>
          ) : (
            <div className="merge-icons" ref={clone_ref}>
              <a href="#">
                <img src="/assets/images/ic_copy.svg" alt="" />
              </a>
            </div>
          )}
          {!checkPlanPermissions('Edit') || !checkMemberPermissions('Edit') ? (
            <Permissions.CallToAction
              withoutIcon={true}
              isPlan={!checkPlanPermissions('Edit')}
              toolTipPosition={'left'}
              toolTipStyle={{ top: 5, left: 20 }}
            >
              <div className="delete-btn" ref={delete_ref}>
                <a href="#">
                  <img src="/assets/images/ic_delete_g.png" alt="" />
                </a>
              </div>
            </Permissions.CallToAction>
          ) : (
            <div className="delete-btn" ref={delete_ref}>
              <a href="#">
                <img src="/assets/images/ic_delete_g.png" alt="" />
              </a>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}
