import React, { useContext, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { createLabel, getLabels, editLabel } from '../../../../services/api/labels.api';
import { actions, LeadsContext } from '../../store/leads.store';
import { FormattedMessage } from 'react-intl';

export default function CreateNewListModal({ toggle, setToggle, label, tracking = {} }) {
  const { state, dispatch } = useContext(LeadsContext);
  const [labelName, setLabelName] = useState((label && label.name) || '');
  const [error, setError] = useState('');
  const handleCreateLabel = async () => {
    const { data, statusCode } = await createLabel(labelName);
    if (statusCode == 200) {
      getLabels().then((data) => {
        dispatch({ type: actions.GET_LABELS, payload: data.labels });
      });
      setToggle(false);
    } else {
      setError(data.messageTranslated || data.message);
    }
  };

  const handleEditLabel = async () => {
    const { data, statusCode } = await editLabel({
      labelId: label.id,
      name: labelName,
    });
    if (statusCode == 200) {
      getLabels().then((data) => {
        dispatch({ type: actions.GET_LABELS, payload: data.labels });
      });
      setToggle(false);
    } else {
      setError(data.messageTranslated || data.message);
    }
  };
  return (
    <Modal show={toggle} onHide={() => setToggle(false)} className="fade create-new-modal">
      <Modal.Header closeButton className="modal-header border-0">
        <div className="modal-inner-header">
          <h4 className="title">
            {label ? (
              <FormattedMessage id={'editList'} />
            ) : (
              <FormattedMessage id={'createNewList'} />
            )}
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body className="modal-body p-0">
        <div className="inner-content">
          <form>
            <div className="form-group">
              <label>
                {label ? (
                  <FormattedMessage id={'editList'} />
                ) : (
                  <FormattedMessage id={'home.leads.enterListNameToSave'} />
                )}
              </label>
              <input type="text" value={labelName} onChange={(e) => setLabelName(e.target.value)} />
            </div>
          </form>
        </div>
        {error && (
          <div
            className="btn-grp text-center  error  kaspr-form-text-error"
            style={{ top: 7, width: '90%' }}
          >
            {error + ' '}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="modal-footer border-0">
        <a
          href="#"
          className="btn2 mr14"
          onClick={() => setToggle(false)}
          data-tracking-id={tracking.cancel}
        >
          <FormattedMessage id={'cancel'} />
        </a>
        <a
          href="#"
          className="btn1 "
          onClick={label ? handleEditLabel : handleCreateLabel}
          data-tracking-id={tracking.action}
        >
          {label ? <FormattedMessage id={'edit'} /> : <FormattedMessage id={'create'} />}
        </a>
      </Modal.Footer>
    </Modal>
  );
}
