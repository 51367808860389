import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Modal, Form, Button, OverlayTrigger } from 'react-bootstrap';
import { SettingsContext } from '../store/Settings.store';
import actions from '../store/Settings.actions';
import cookie from 'react-cookies';
import { BASE_URL, CHROME_STORE, EXTENSION_ID, IS_PROD } from '../../../constants';

import LinkedinConnect from '../../Auth/Onboarding/LinkedinConnect/LinkedinConnect';
import {
  changePhone,
  getUser,
  phoneVerificationRequest,
  validatePhone,
  isPhoneTokenSent,
  updateUser,
  getKasprInvitations,
} from '../../../services/api/auth.api';
import {
  ConfirmPhonePopUp,
  PhoneValidation,
} from '../../Auth/Onboarding/PhoneValidation/PhoneValidation';
import { getCredits, getSubscriptionInfos } from '../../../services/api/credits.api';
import { deleteAccount } from '../../../services/api/auth.api';

import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import InviteFriend from '../../Auth/Onboarding/InviteFriend/InviteFriend';
import { InfoToolTip, Language, Tooltip } from '../../../components';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import {
  getNotificationsSettings,
  updatedNotificationsSettings,
} from '../../../services/api/notifications.api';
import ProfileInfos from './ProfileInfos/ProfileInfos';
import queryString from 'query-string';
import { getMappedNotifSettings } from '../tools';
import { useUserSteps } from '../../Home/Home.hooks';
import { useNavigate } from 'react-router-dom';
import CreditsSection from '../../../components/CreditsSection/CreditsSection';
import { calculateRemaining } from '../../../services/utils/tools';
/*======== PROFILE ========*/
function Profile(props) {
  const [invitationsNumber, setInvitationsNumber] = useState(0);
  const [notificationsSettings, setNotificationsSettings] = useState(null);
  useEffect(() => {
    getNotificationsSettings().then(({ data, statusCode }) => {
      if (statusCode == '200') {
        const mappedData = getMappedNotifSettings(data, ['task']);
        setNotificationsSettings(mappedData);
      }
    });
  }, []);
  const handleUpdateSettings = async ({ fieldsToActivate, type }) => {
    const settings = {
      ...notificationsSettings.settings,
      ...(type === 'emails'
        ? {
            enabledEmails: fieldsToActivate,
            enabledNotifications: notificationsSettings.settings.enabledNotifications,
          }
        : {
            enabledNotifications: fieldsToActivate,
            enabledEmails: notificationsSettings.settings.enabledEmails,
          }),
    };
    const { data, statusCode } = await updatedNotificationsSettings(settings);
    if (statusCode == 200) {
      setNotificationsSettings({ ...notificationsSettings, settings });
    } else {
      setNotificationsSettings(notificationsSettings);
    }
  };
  let isVersion2 = !props.organizationCredits
    ? true
    : props.organizationCredits &&
      props.organizationCredits.plan &&
      props.organizationCredits.plan.version == 2;
  return (
    <div className={'tab-pane active show'}>
      <ProfileInfos profile={props.profile} />
      {isVersion2 && (
        <div className={'content-box-ui'}>
          <div className="content-header with-progress p-16">
            <div className="title-ui">
              <label>
                <FormattedMessage id={'home.yourCredits'} />
                {props.organizationCredits && props.organizationCredits.subscription && (
                  <p style={{ margin: 2, fontSize: 12, color: '#888' }}>
                    <img
                      style={{ width: 14, marginTop: -3 }}
                      src="/assets/images/icon-info.svg"
                      alt=""
                    />{' '}
                    <FormattedMessage id={'settings.cannotBeUsedIf'} />
                  </p>
                )}
              </label>
            </div>
          </div>
          <div
            style={{
              opacity:
                props.organizationCredits && props.organizationCredits.subscription ? 0.45 : 1,
              marginTop: -20,
            }}
          >
            {/*{props.organizationCredits &&
                props.organizationCredits}*/}
            <CreditsSection
              remaining={calculateRemaining(
                props.remaining,
                {
                  personalCredits: props.personalCredits,
                },
                'personalCredits',
              )}
            />
          </div>
        </div>
      )}
      {isVersion2 && (
        <ProfileOnboarding
          profile={props.profile}
          resetCredits={props.resetCredits}
          invitationsNumber={invitationsNumber}
        />
      )}
      <EmailPreferences
        profile={props.profile}
        resetNewsletter={props.resetNewsletter}
        organization={props.organization && props.organization.organization}
      />
      <NotificationsSettings
        config={notificationsSettings}
        handleUpdateSettings={handleUpdateSettings}
      />
      <LanguageBox profile={props.profile} />
      <ShowHideWidget user={props.profile} />
      <DeleteAccount />
    </div>
  );
}

/*======== EMAIL PREFERENCES ========*/
function EmailPreferences({ profile, resetNewsletter, organization }) {
  const [newsletter, setNewsletter] = useState(profile.receiveNewsletter);
  const [hidePersonalEmail, setHidePersonalEmail] = useState(profile.hidePersonalEmail);
  const intl = useIntl();
  const handleEmailPreferences = (e, type) => {
    if (e) e.stopPropagation();
    const profileFields = Object.assign({}, profile);
    switch (type) {
      case 'newsletter':
        profileFields.receiveNewsletter = e.target.checked;
        break;
      case 'hidePersonalEmail':
        profileFields.hidePersonalEmail = e.target.checked;
        break;
    }
    updateUser(profileFields).then(({ data, statusCode }) => {
      if (statusCode === 200) {
        resetNewsletter({
          ...profile,
          receiveNewsletter: data.receiveNewsletter,
          hidePersonalEmail: data.hidePersonalEmail,
        });
        setNewsletter(data.receiveNewsletter);
        setHidePersonalEmail(data.hidePersonalEmail);
      }
    });
  };

  return (
    <div className="content-box-ui">
      <div className="content-header">
        <div className="title-ui text-left">
          <label>
            <FormattedMessage id={'settings.profile.emailPreferences'} />
          </label>
        </div>
      </div>
      <div className="body-content">
        <div className="inner-body-content email-listing-ui">
          <ul>
            <li>
              <div className="checkbox-wrap">
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => handleEmailPreferences(e, 'newsletter')}
                    checked={newsletter}
                  />
                  <span className="checkbox-icon"></span>
                  <div className="checkbox-text">
                    <span>Newsletter</span>
                    <p>
                      <FormattedMessage id={'settings.profile.acceptNewsletter'} />
                    </p>
                  </div>
                </label>
              </div>
            </li>
            <li>
              {!!organization ? (
                <div className="checkbox-wrap">
                  <label>
                    <input
                      type="checkbox"
                      value=""
                      checked={organization.hidePersonalEmail}
                      disabled={true}
                    />
                    <span className="checkbox-icon"></span>
                    <div className="checkbox-text">
                      <OverlayTrigger
                        placement={'top'}
                        key={'hide-personal-email'}
                        overlay={(props) =>
                          Tooltip({
                            ...props,
                            data: intl.formatMessage({
                              id: 'settings.hidePersonalEmailToolTip',
                            }),
                            container_style: { padding: '0px 5px' },
                          })
                        }
                        defaultShow={false}
                      >
                        <span style={{ color: '#999' }}>
                          <FormattedMessage id={'settings.profile.hidePersonalEmail'} />
                        </span>
                      </OverlayTrigger>

                      <p style={{ color: '#ccc' }}>
                        <FormattedMessage id={'settings.profile.hidePersonalEmail.text'} />
                      </p>
                    </div>
                  </label>
                </div>
              ) : (
                <div className="checkbox-wrap">
                  <label>
                    <input
                      type="checkbox"
                      value=""
                      onClick={(e) => handleEmailPreferences(e, 'hidePersonalEmail')}
                      checked={hidePersonalEmail}
                    />
                    <span className="checkbox-icon"></span>
                    <div className="checkbox-text">
                      <span>
                        <FormattedMessage id={'settings.profile.hidePersonalEmail'} />
                      </span>
                      <p>
                        <FormattedMessage id={'settings.profile.hidePersonalEmail.text'} />
                      </p>
                    </div>
                  </label>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
function NotificationsSettings({ config, handleUpdateSettings }) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  if (!config) return <div />;
  const handleUpdateField = async ({ field, type }) => {
    let fieldsToActivate = Object.assign(
      [],
      type == 'emails' ? config.settings.enabledEmails : config.settings.enabledNotifications,
    );
    if (!field.field) {
      setSelectedCategory(field.category);
      let mappedEmails = config.emailsMapped
        .filter((email) => email.category == field.category)
        .map((email) => email.templates)
        .flat();
      let mappedNotifications = config.notificationsMapped
        .filter((notification) => notification.category == field.category)
        .map((notification) => notification.templates)
        .flat();
      if (field.checked) {
        fieldsToActivate =
          type == 'emails'
            ? [...config.settings.enabledEmails, ...mappedEmails]
            : [...config.settings.enabledNotifications, ...mappedNotifications];
      } else {
        let emailsToActivate = Object.assign([], config.settings.enabledEmails);
        emailsToActivate = emailsToActivate.filter(
          (emailToActivate) => !mappedEmails.some((mappedEmail) => mappedEmail == emailToActivate),
        );
        let notificationsToActivate = Object.assign([], config.settings.enabledNotifications);
        notificationsToActivate = notificationsToActivate.filter(
          (notificationToActivate) =>
            !mappedNotifications.some(
              (mappedNotification) => mappedNotification == notificationToActivate,
            ),
        );
        fieldsToActivate = type == 'emails' ? emailsToActivate : notificationsToActivate;
      }

      return await handleUpdateSettings({ fieldsToActivate, type });
    }
    if (!field.checked) {
      fieldsToActivate = [...fieldsToActivate, field.field];
    } else {
      fieldsToActivate = fieldsToActivate.filter(
        (fieldToActivate) => fieldToActivate !== field.field,
      );
    }
    await handleUpdateSettings({ fieldsToActivate, type });
  };
  return (
    <div className={'notifications-settings'}>
      <div className="content-box-ui">
        <div className="content-header">
          <div className="title-ui text-left">
            <label>
              <FormattedMessage id={'settings.notifications.title'} />
            </label>
          </div>
        </div>
        <div className="body-content">
          <div className="inner-body-content ">
            <div className={'settings-title'}>
              <p className={''}>Emails</p>
              <span>
                <FormattedMessage id={'settings.notifications.emailsDescription'} />
              </span>
            </div>
            {config &&
              config.emailsMapped.map((category, i) => (
                <NotificationCategory
                  category={category}
                  isSelectedCategory={category.category == selectedCategory}
                  key={i}
                  settings={config.settings}
                  type={'emails'}
                  updateField={handleUpdateField}
                />
              ))}
          </div>
          <div className="inner-body-content ">
            <div className={'settings-title'}>
              <p className={''}>
                <FormattedMessage id={'settings.notifications'} />
              </p>
              <span>
                <FormattedMessage id={'settings.notifications.notificationsDescription'} />
              </span>
            </div>

            {config &&
              config.notificationsMapped.map((category, i) => (
                <NotificationCategory
                  category={category}
                  key={i}
                  settings={config.settings}
                  type={'notifications'}
                  isSelectedCategory={category.category == selectedCategory}
                  updateField={handleUpdateField}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
function NotificationCategory({ category, settings, type, updateField, isSelectedCategory }) {
  const [globalCheck, setGlobalCheck] = useState(null);
  const [fields, setFields] = useState(null);

  useEffect(() => {
    const checkEnabled = {};
    if (type == 'emails') {
      settings.enabledEmails.forEach((enabled) => (checkEnabled[enabled] = true));
    }
    if (type == 'notifications') {
      settings.enabledNotifications.forEach((enabled) => (checkEnabled[enabled] = true));
    }

    const fields = category.templates.map((field) =>
      checkEnabled[field] ? { field, checked: true } : { field, checked: false },
    );
    setGlobalCheck(!!fields.find((field) => field.checked == true) && isSelectedCategory !== null);
    setFields(fields);
  }, [settings]);

  if (globalCheck == null) return <div />;
  return (
    <div className={'notification-category'}>
      <ul>
        <li>
          <div
            className="checkbox-wrap"
            onClick={() =>
              updateField({
                type,
                field: {
                  field: null,
                  checked: !globalCheck,
                  category: category.category,
                },
              })
            }
          >
            <label>
              <input type="checkbox" onChange={() => globalCheck} checked={globalCheck} />
              <span className="checkbox-icon"></span>
              <div className="checkbox-text">
                <span className={'text-capitalize'}>
                  <FormattedMessage id={'settings.notifications.' + category.category} />
                </span>
                <p>
                  <FormattedMessage id={'settings.notifications.enableOrDisable'} />
                </p>
              </div>
            </label>
          </div>
        </li>
      </ul>
      {globalCheck && (
        <div className={'notif-settings-container'}>
          {fields.map((field, i) => (
            <div key={i} className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  value=""
                  checked={field.checked}
                  onChange={() => updateField({ field, type })}
                />
                <span className="checkbox-icon"></span>
              </label>
              <span>
                <FormattedMessage id={'settings.notifications.' + field.field} />{' '}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

/*======== SHOW HIDE WIDGET ========*/
function ShowHideWidget({ user }) {
  const [isWidgetHidden, setIsWidgetHidden] = useState(false);
  const intl = useIntl();
  useEffect(() => {
    setIsWidgetHidden(user.hideWidget);
  }, []);

  const handleWidgetActivation = (e) => {
    e.preventDefault();
    setIsWidgetHidden(!isWidgetHidden);
    let userFields = user;
    userFields.hideWidget = !isWidgetHidden;

    if (window.chrome && window.chrome.runtime && window.chrome.runtime.sendMessage) {
      window.chrome.runtime.sendMessage(EXTENSION_ID, {
        action: 'SET_WIDGET_STATUS',
        input: { userFields },
      });
    }
  };
  return (
    <div className="content-box-ui ">
      <div className="inner-body-content">
        <ul style={{ margin: 0 }}>
          <li style={{ margin: 0 }}>
            <div className="checkbox-wrap" onClick={handleWidgetActivation}>
              <label>
                <input type="checkbox" checked={isWidgetHidden} readOnly={true} />
                <span className="checkbox-icon"></span>
                <div className="checkbox-text">
                  <span>{intl.formatMessage({ id: 'settings.hideWidget' })}</span>
                  <p>
                    {intl.formatMessage({
                      id: 'settings.theWidgetWillNotBeVisibleOnLinkedin',
                    })}
                  </p>
                </div>
              </label>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
/*======== LANGUAGE ========*/
function LanguageBox(props) {
  const { state, dispatch } = useContext(SettingsContext);
  const [selectToggle, setSelectToggle] = useState(null);
  const handleSelectLanguage = async (e) => {
    const profileFields = Object.assign({}, props.profile);
    profileFields.language = e;
    await updateUser(profileFields);
    window.location = '/settings';
  };
  return (
    <div className="content-box-ui ">
      <div className="content-header">
        <div className="title-ui text-left">
          <label>
            <FormattedMessage id={'settings.profile.language'} />
          </label>
          <p>
            <FormattedMessage id={'settings.profile.pleaseSelectAPreferredLang'} />
          </p>
        </div>
        <div className="button-ui language-dropdown">
          <Language
            setSelectToggle={setSelectToggle}
            selectToggle={selectToggle}
            profile={props.profile}
            handleSelectLanguage={handleSelectLanguage}
          />
        </div>
      </div>
    </div>
  );
}

/*======== DELETE ACCOUNT ========*/
function DeleteAccount({ intl }) {
  let [modalToggle, setModalToggle] = useState(false);
  let [error, setError] = useState(null);
  let [text, setText] = useState(null);

  const handleDeleteAccount = async () => {
    if (text !== intl.formatMessage({ id: 'settings.profile.deleteMyAccount' })) {
      setError(intl.formatMessage({ id: 'organization.pleaseEnterTheValidPhrase' }));
      return;
    }
    let { data, statusCode } = await deleteAccount();
    if (statusCode === 200) {
      cookie.remove('access_token', { path: '/', domain: BASE_URL });
      window.location = '/';
    } else setError(data.messageTranslated || data.message);
  };

  const handleModal = () => {
    setModalToggle(!modalToggle);
    setError(null);
  };

  return (
    <div className="content-box-ui">
      <div className="content-header">
        <div className="title-ui text-left">
          <label>
            <FormattedMessage id={'settings.profile.deleteMyAccount'} />
          </label>
          <p>
            <FormattedMessage
              id={'settings.profile.byClosingYourAccount'}
              values={{
                strong: (chunk) => <strong>{chunk} </strong>,
              }}
            />
          </p>
        </div>
        <div className="button-ui delete-account">
          <button
            onClick={handleModal}
            className="delete-account-btn"
            style={{
              fontSize: 13,
              maxWidth: 'max-content',
              width: 'max-content',
            }}
          >
            <FormattedMessage id={'settings.profile.deleteMyAccount'} />
          </button>
        </div>
      </div>
      <Modal
        show={modalToggle}
        onHide={handleModal}
        className="fade  delete-organization-wrapper"
        id="delete-organization"
      >
        <Modal.Header closeButton className="border-0">
          <button type="button" className="close" data-dismiss="modal">
            <img src="/assets/images/ic-cross.png" alt="" />
          </button>
          <div className="modal-inner-header">
            <h4 className="title">
              {' '}
              <FormattedMessage id={'settings.profile.deleteAccount'} />
            </h4>
          </div>
        </Modal.Header>
        <div className="modal-body p-24">
          <div className="delete-inner-ui">
            <div className="alert-wrap">
              <span className="ic-alert">
                <img src="/assets/images/ic-alert.svg" alt="" />
              </span>
              <label>
                <FormattedMessage id={'settings.profile.yourAboutToDeleteYourAccount'} />
              </label>
              <p>
                <FormattedMessage
                  id={'settings.profile.onceAnAccountIsDeleted'}
                  values={{
                    span: (chunk) => <span> {chunk} </span>,
                    br: <br />,
                  }}
                />
              </p>
            </div>

            <p>
              <FormattedMessage
                id={'settings.profile.thisActionCannotBeUndone'}
                values={{ br: <br />, span: (chunk) => <span>{chunk} </span> }}
              />
            </p>
            <div className="form-fields">
              <a href="#" className="delete-tag">
                “<FormattedMessage id={'settings.profile.deleteMyAccount'} />”
              </a>
              <div className="form-group">
                <input
                  type="text"
                  name=""
                  placeholder={intl.formatMessage({
                    id: 'settings.profile.typeHere',
                  })}
                  onChange={(e) => setText(e.target.value)}
                  style={
                    error ? { border: '1px solid red', color: '#981830' } : { color: '#981830' }
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {error && (
          <div className="btn-grp text-center  error  kaspr-form-text-error">{error + ' '}</div>
        )}
        <div className="modal-footer border-0">
          <a href="#" className="btn6" onClick={handleModal}>
            <FormattedMessage id={'settings.profile.cancelKeepIt'} />
          </a>
          <a href="#" className="btn5 ml-20" onClick={handleDeleteAccount}>
            <FormattedMessage id={'settings.profile.yesDeleteMyAccount'} />
          </a>
        </div>
      </Modal>
    </div>
  );
}

DeleteAccount = injectIntl(DeleteAccount);

function ProfileOnboarding({ profile, resetCredits, invitationsNumber }) {
  const navigate = useNavigate();
  const { steps, selectedStep } = useUserSteps(profile);
  const handleStepClick = (step) => {
    navigate('/onboarding/' + step.urlSegment);
  };
  let progression = steps.filter((step) => step.done).length;
  progression = Math.floor((progression * 100) / 6);
  return (
    <div className="content-box-ui onboarding-settings">
      <div className="content-header with-progress p-16">
        <div className="title-ui">
          <label>
            <FormattedMessage id={'home.onboarding'} />
          </label>
          <p>
            <FormattedMessage id={'home.completeYourProfile.text'} />
          </p>
        </div>
        <div className={'progress-container'}>
          <progress id="file" max="100" value={progression} />
          <p>
            {' '}
            <FormattedMessage id={'%completed'} values={{ value: progression }} />{' '}
          </p>
        </div>
      </div>
      {steps.map((step, i) => (
        <OnboardingItem
          key={i}
          step={step}
          onStepClick={() => handleStepClick(step)}
          selected={selectedStep}
        />
      ))}
    </div>
  );
}
function OnboardingItem({ step, onStepClick, selected }) {
  return (
    <div
      className={'onBoarding-settings-item ' + (step.done ? 'validated' : '')}
      // style={selected == step.id ? { borderColor: "#333" } : {}}
      onClick={onStepClick}
    >
      <div className={'onBoarding-settings-item-wrapper'}>
        <div className={'onBoarding-item-icon'}>
          <img src={'/assets/images/' + step.icon} alt="" />{' '}
        </div>
        <div className={'onBoarding-item-content'}>
          <h3>{step.title} </h3>
          <p style={{ color: step.color }} className={'credits-wrp'}>
            <span className={'plus'}>+</span> <span>{step.creditsText}</span>{' '}
          </p>
        </div>
      </div>
      {step.done ? (
        <div>
          <FormattedMessage id={'home.completed'} />
        </div>
      ) : (
        <p
          /*style={{fontWeight:500}}*/ className={
            step.id == 'inviteFriends' ? 'kaspr-gradient' : 'blue-color'
          }
        >
          <FormattedMessage id={'settings.onboarding.' + step.id} />
        </p>
      )}
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setNavBarToggle: (navToggle) => {
    dispatch({ type: actions.NAVBAR_TOGGLE, navToggle });
  },
  resetCredits: () => {
    getCredits().then(({ data, statusCode }) => {
      dispatch({ type: actions.GET_CREDITS, payload: data });
    });
    getUser().then(({ data }) => {
      dispatch({ type: actions.GET_USER, payload: data });
    });
  },
  resetNewsletter: (profile) => {
    dispatch({
      type: 'GET_USER',
      payload: profile,
      permissions: profile.permissions,
    });
  },
});

const mapStateToProps = (state) => ({ redux: state });
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
